import Vue from "vue";
import Vuex from "vuex";
import actions from "@/store/modules/actions.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    devEnv: false,
    status: "",
    statusOffer: "",
    pointEntry: false,
    user: JSON.parse(localStorage.getItem("info")) || {},
    token: localStorage.getItem("token") || false,
    offerDefaultOptions: null,
    defaultSettings: null,
    ordersDefaultOptions: null,
    programs: [],
    orders: [],
    companies: [],
    staff: [],
    stores: [],
    regions: [],
    showDialog: false
  },
  mutations: {
    auth_status(state, [token, code]) {
      state.status = code;
      state.token = token;
    },
    auth_error(state, code) {
      state.status = code;
      state.token = false;
      state.user = {};
      state.orders = [];
      state.offerDefaultOptions = null;
      localStorage.removeItem("token");
      localStorage.removeItem("info");
    },
    logout(state) {
      state.status = "";
      state.token = false;
      state.user = {};
      state.orders = [];
      state.offerDefaultOptions = null;
      localStorage.removeItem("token");
      localStorage.removeItem("info");
    },
    userInfo_success(state, user) {
      state.user = user;
    },
    userInfo_error(state, code) {
      state.status = code;
      state.user = {};
    },
    offerDefaultOptions_success(state, options) {
      state.offerDefaultOptions = options;
    },
    offerDefaultOptions_error(state, code) {
      state.status = code;
      state.offerDefaultOptions = null;
    },
    ordersDefaultOptions_success(state, options) {
      state.ordersDefaultOptions = options;
    },
    ordersDefaultOptions_error(state, code) {
      state.status = code;
      state.ordersDefaultOptions = null;
    },
    programs_success(state, data) {
      state.programs = data;
    },
    programs_error(state, code) {
      state.status = code;
      state.programs = [];
    },
    orders_success(state, data) {
      state.status = "success";
      state.orders = data;
    },
    orders_error(state, code) {
      state.status = code;
      state.orders = [];
    },
    offer_status(state, status) {
      state.statusOffer = status;
    },
    show_dialog(state, mode) {
      state.showDialog = mode;
    },
    defaultSettings_success(state, options) {
      state.defaultSettings = options;
    },
    defaultSettings_error(state, code) {
      state.status = code;
      state.defaultSettings = null;
    },
    ecaUserInfo_success(state, user) {
      state.user = user;
    },
    ecaUserInfo_error(state, code) {
      state.status = code;
      state.user = {};
    },
    companies_success(state, data) {
      state.companies = data;
    },
    companies_error(state, code) {
      state.status = code;
      state.companies = [];
    },
    staff_success(state, data) {
      state.staff = data;
    },
    staff_error(state, code) {
      state.status = code;
      state.staff = [];
    },
    stores_success(state, data) {
      state.stores = data;
    },
    stores_error(state, code) {
      state.status = code;
      state.stores = [];
    },
    regions_success(state, data) {
      state.regions = data;
    },
    regions_error(state, code) {
      state.status = code;
      state.regions = [];
    }
  },
  actions: {},
  modules: {
    actions
  },
  getters: {
    isLogged: state => !!state.token,
    getStatus: state => state.status,
    getStatusOffer: state => state.statusOffer,
    getPointOfEntry: state => state.user?.esaSid,
    getUser: state => state.user,
    getAllPrograms: state => state.programs,
    getOrdersAll: state => state.orders,
    getOfferOptions: state => state.offerDefaultOptions,
    getOrdersAndSettingsOptions: state => state.ordersDefaultOptions,
    getShowDialog: state => state.showDialog,
    getAllOrg: state => state.companies,
    getStaff: state => state.staff,
    getDefSettings: state => state.defaultSettings,
    getStores: state => state.stores,
    getRegions: state => state.regions,
    devEnv: state => state.devEnv
  }
});

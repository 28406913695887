<template>
  <v-list>
    <v-subheader class="text-subtitle-1">
      <IconLogo />
    </v-subheader>
    <v-divider></v-divider>

    <v-list-item
      v-for="link in menu"
      :key="link.url"
      :to="link.url"
      :exact="link.exact"
      :class="{ hidden: !link.show }"
      class="text--darken-2"
      color="privat"
    >
      <v-icon class="mr-4">{{ link.icon }}</v-icon>
      {{ link.title }}
    </v-list-item>

    <v-list-item class="hidden-sm-and-up" @click="logout">
      <v-icon class="mr-4">mdi-logout-variant</v-icon>
      {{ $t("nav.logout") }}
    </v-list-item>
  </v-list>
</template>

<script>
import IconLogo from "@/components/app/AppLogo";
import { mapGetters } from "vuex";

export default {
  name: "AppNavbar",
  computed: {
    ...mapGetters(["getUser", "getPointOfEntry"]),
    menu() {
      return [
        {
          title: this.$i18n.t("nav.orders"),
          icon: "mdi-file-document-edit",
          url: "/orders",
          exact: true,
          show: true
        },
        {
          title: this.$i18n.t("nav.statistic"),
          icon: "mdi-chart-bar-stacked",
          url: "/statistic",
          show: this.statisticAccess
        },
        {
          title: this.$i18n.t("nav.settings"),
          icon: "mdi-cog",
          url: "/settings/staff",
          show: this.isAvailableSettings && !this.authEsa
        },
        {
          title: this.$i18n.t("nav.settings"),
          icon: "mdi-cog",
          url: "/settings/companies",
          show: this.authEsa && this.isAdmin
        }
        /*{
          title: "Home",
          icon: "mdi-home",
          url: "/home"
        },*/
      ];
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    isAvailableSettings() {
      return this.getUser?.isManageAvailable;
    },
    isAdmin() {
      return this.getUser?.userGrants?.includes("ADMINISTRATOR");
    },
    statisticAccess() {
      const userRole = this.getUser?.role?.value;

      return this.isAdmin || userRole === "HEAD" || userRole === "DIRECTOR";
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("showDialog", "logout");
    }
  },
  components: {
    IconLogo
  }
};
</script>

<template>
  <v-app-bar color="white" fixed app>
    <v-app-bar-nav-icon
      @click="$emit('chowDrawer')"
      class="hidden-md-and-up ml-n2 mr-2"
      aria-label="menu"
    >
      <v-icon>mdi-menu</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title class="d-md-flex hidden-sm-and-down ml-4 mr-4 pl-0">
      <IconLogo />
    </v-toolbar-title>

    <v-bottom-navigation
      class="hidden-sm-and-down"
      v-if="status !== 'create' && status !== 'finish'"
    >
      <v-btn id="nav-orders" to="/orders" :ripple="false">
        {{ $t("nav.orders") }}
      </v-btn>
      <v-btn
        id="nav-statistic"
        to="/statistic"
        :ripple="false"
        v-if="statisticAccess"
      >
        {{ $t("nav.statistic") }}
      </v-btn>
      <v-btn
        id="nav-settings"
        ref="navSettings"
        to="/settings/staff"
        :ripple="false"
        v-if="isAvailableSettings && !authEsa"
      >
        {{ $t("nav.settings") }}
      </v-btn>
      <v-btn
        id="nav-settings"
        to="/settings/companies"
        :ripple="false"
        v-if="authEsa && isAdmin"
      >
        {{ $t("nav.settings") }}
      </v-btn>
      <!--<v-btn id="home" to="/home" :ripple="false">HOME</v-btn>-->
    </v-bottom-navigation>

    <v-btn
      v-show="showBtnCreateOffer"
      class="btn-not-focus mr-5"
      text
      :ripple="false"
      :aria-label="$t('action.createOffer')"
      @click="goToOffer"
    >
      <v-icon class="mr-3" color="privat">mdi-plus-circle</v-icon>
      {{ $t("action.createOffer") }}
    </v-btn>

    <v-spacer v-if="status === 'create' || status === 'finish'"></v-spacer>
    <v-btn
      v-show="showBtnCloseOffer"
      class="btn-not-focus mr-5"
      text
      :ripple="false"
      :aria-label="$t('action.closeOffer')"
      @click="closeOffer"
    >
      <v-icon class="mr-3" color="grey darken-1">mdi-close-circle</v-icon>
      {{ $t("action.closeOffer") }}
    </v-btn>

    <v-spacer class="hidden-md-and-up"></v-spacer>
    <v-divider class="v-divider--vertical hidden-xs-only"></v-divider>
    <v-app-bar-nav-icon
      id="logout"
      class="ml-2 mr-n2 ml-md-4 mr-md-1 hidden-xs-only"
      @click="logout"
      aria-label="logout"
      :ripple="false"
    >
      <v-icon>mdi-logout-variant</v-icon>
    </v-app-bar-nav-icon>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <a
          class="router-link action-add action-add--fixed"
          @click="goToOffer"
          v-show="showBtnCreateOffer"
        >
          <v-fab-transition>
            <v-btn
              class="mx-2"
              color="privat"
              small
              dark
              fab
              :ripple="false"
              :aria-label="$t('action.createOffer')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark style="margin-top: 2px;">mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </a>
      </template>
      <span>{{ $t("action.createOffer") }}</span>
    </v-tooltip>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <a class="router-link action-add action-add--fixed">
          <v-fab-transition>
            <v-btn
              v-show="showBtnCloseOffer"
              color="grey darken-1 mx-2"
              @click="closeOffer"
              small
              dark
              fab
              :ripple="false"
              :aria-label="$t('action.closeOffer')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark style="margin-top: 2px;">mdi-close</v-icon>
            </v-btn>
          </v-fab-transition>
        </a>
      </template>
      <span>{{ $t("action.closeOffer") }}</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
import IconLogo from "@/components/app/AppLogo";
import { mapGetters } from "vuex";

export default {
  name: "AppHeader",
  props: ["drawer"],
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$store.dispatch("showDialog", "logout");
    },
    goToOffer() {
      this.$store.dispatch("setOfferStatus", "create");
      this.$router.push("/offer");
    },
    closeOffer() {
      this.$store.dispatch("showDialog", "close_offer_orders");
    }
  },
  computed: {
    ...mapGetters(["getUser", "getStatusOffer", "getPointOfEntry"]),
    isAvailableSettings() {
      return this.getUser?.isManageAvailable;
    },
    status() {
      return this.getStatusOffer;
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    isAdmin() {
      return this.getUser?.userGrants?.includes("ADMINISTRATOR");
    },
    statisticAccess() {
      const userRole = this.getUser?.role;

      return (
        this.isAdmin ||
        userRole?.value === "HEAD" ||
        userRole?.value === "DIRECTOR"
      );
    },
    isOnlySupport() {
      const userGrants = this.getUser?.userGrants;

      return userGrants?.length === 1 && userGrants?.includes("SUPPORT_PB");
    },
    showBtnCreateOffer() {
      const status = this.status;

      return !this.isOnlySupport && status !== "create" && status !== "finish";
    },
    showBtnCloseOffer() {
      return !this.isOnlySupport && this.status === "create";
    }
  },
  components: {
    IconLogo
  }
};
</script>

<style lang="scss">
.router-link {
  text-decoration: none;
}
.logo-org {
  max-width: 125px;
  font-weight: bold;
}
.action-add--fixed {
  position: fixed;
  top: 35px;
  right: 15px;
  @media (min-width: 600px) {
    right: 87px;
  }
  @media (min-width: 960px) {
    display: none;
  }
}
.theme--light {
  .v-btn {
    &.btn-not-focus {
      text-transform: none;
      &:hover::before,
      &:focus::before {
        opacity: 0 !important;
      }
      @media (max-width: 959px) {
        display: none;
      }
    }
  }
}

#app {
  .v-toolbar__title {
    align-items: center;
    min-width: 113px;
    min-height: 40px;
  }
  .v-sheet.v-app-bar.v-toolbar {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.07), 0 2px 8px rgba(0, 0, 0, 0.08),
      0 4px 10px rgba(0, 0, 0, 0.07);
  }
  .v-toolbar__content {
    padding-top: 0;
    padding-bottom: 0;
  }
  .v-item-group.v-bottom-navigation {
    box-shadow: none;
    height: 100% !important;
    .v-btn {
      font-size: 14px;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 0;
        height: 2px;
        //background-color: #1976d2;
        background-color: var(--color-privat);
        transition: all 0.5s ease;
        opacity: 0.2;
      }
      &--active {
        //color: #1976d2;
        color: var(--color-privat);
        &::after {
          width: 100%;
          opacity: 1;
          transition: all 0.3s ease;
        }
      }
    }
  }
}
</style>

<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
import axios from "axios";

export default {
  name: "app",
  data() {
    return {
      date: new Date()
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    }
  },
  components: {
    EmptyLayout,
    MainLayout
  },
  methods: {},
  created() {
    console.log("app version: 4.8.1");
    axios.interceptors.response.use(undefined, err => {
      return new Promise(() => {
        console.dir(err);
        if (err.request.status && err.config && !err.config.__isRetryRequest) {
          let status = err.request.status;

          console.log("err.status === ", status);
          if (status === 500 || status.toString().includes("50")) {
            this.$router.push("/error/500");
          }
          if (status === 401) {
            this.$router.push("/error/401");
          }
          if (status === 403) {
            this.$router.push("/error/403");
          }
          if (status === 404) {
            this.$router.push("/error/404");
          }
        }
        throw err;
      });
    });
  }
};
</script>

<style lang="scss">
@import "style";
</style>

/**
 * Created by dn050981zvm on 28.10.2020.
 */
const ERROR_SYSTEM_UA = "Відбулася системна помилка";
const ERROR_SYSTEM_RU = "Произошла системная ошибка";
const ERROR_SOMETHING_UA =
  "На жаль, щось пішло не так. Спробуйте ще раз пізніше.";
const ERROR_SOMETHING_RU =
  "К сожалению, что-то пошло не так. Попробуйте позже.";
const CONTACT_MANAGER_UA = "зверніться до Вашого менеджера від Банку.";
const CONTACT_MANAGER_RU = "обратитесь к Вашему менеджеру от Банка.";
const INCORRECT_DATA_UA = "Некоректні дані.";
const INCORRECT_DATA_RU = "Некорректные данные.";
export const lang = {
  ru: {
    load: "Загрузка... Подождите пожалуйста",
    home: {
      title: "Доброго дня, {user}!",
      text: {
        welcome: "Добро пожаловать в финансовый лизинг",
        managerHelp: {
          $1: "Чтобы получить помощь по программе финансового лизинга,",
          $2: "обращайтесь к менеджеру нашего банка"
        },
        settings: "Настройки"
      }
    },
    auth: {
      enterPhone:
        "Введите номер на который будет отправлено SMS с одноразовым паролем для входа",
      enterOtp: "На номер {tel} отправлено сообщение с кодом для авторизации"
    },
    orders: {
      title: "Заявки в работе",
      title_pdf: "Просмотр предложения",
      notOrders: "У вас нет активных заявок",
      columns: {
        crDt: "Дата",
        orgName: "Компания",
        okpo: "ЕГРПОУ/ИНН",
        cost: "Сумма",
        term: "Срок",
        status: "Статус",
        pledgeQuantity: "Кол.зал",
        leasingSubject: "Предметы лизинга",
        type: "Тип",
        condition: "Состояние",
        brand: "Бренд",
        model: "Марка",
        lowerPercent: "Скидка для банка",
        cpAuthor: "Автор",
        seller: "Поставщик",
        client: "Клиент"
      },
      state: {
        CP_SAVED: "Сохранена",
        JOURNAL_ACCEPTED: "Принятая банком",
        JOURNAL_DUPLICATE: "Дубликат заявки",
        JOURNAL_ERROR: "Не принята банком"
      }
    },
    offer: {
      title: "Создание заявки",
      subTitle: {
        subject: "Предмет лизинга",
        general: "Общие параметры",
        program579s: 'Компенсация "Доступные кредиты 5-7-9%"',
        stateGuarantee: "Государственная гарантия обеспечения",
        individualConditions: "Индивидуальные условия",
        baseAccrual: "База начисления:"
      },
      allTypeFinancing: {
        FL: "Финансовый лизинг",
        FL_579: "Финансовый лизинг 5-7-9%"
      },
      overlay: {
        title: "Подождите, пожалуйста",
        dataProcessing: "идет обработка данных",
        individualCalculation:
          "Идет индивидуальный расчет предложения по лизингу",
        timeProcessing: "это занимает в среднем не более {time}",
        time_30sec: "30 секунд",
        time_3min: "3 минут"
      },
      step: {
        zero: "Выберите программу финансирования",
        oneDealer: "Шаг 1 - выбор/поиск дилера",
        oneProgram: "Шаг 1 - выберите программу финансирования",
        oneClient: "Шаг 1 - авторизация клиента",
        two: "Шаг 2 - предмет лизинга и стоимость",
        three: "Шаг 3 - выбор варианта финансирования",
        threeClientNotNear: "Шаг 3 - подтверждение заявки",
        four: "Шаг 4 - подтверждение заявки",
        finish: "КП успешно создано",
        finish_text_1:
          "Согласно этому расчету, подача заявки в банк доступна в течение 2 суток.",
        finish_text_2:
          "С согласия клиента, вы можете отправить заявку сейчас или через журнал заявок в течение следующих 48 часов."
      },
      attachFile: "Необходимо прикрепить файл спецификаций",
      createMeeting: "Необходимо создать встречу",
      addressMeeting: "Адрес проведения встречи",
      sendToEmail: "Отправить КП на почту",
      chooseClientPhone:
        "Выберите номер на который будет отправлено сообщение от ПриватБанка с кодом подтверждения",
      tooltip: {
        discount: "Полная стимость для банка за вычетом скидки",
        prepayment: "Сумма первоначального взноса",
        term:
          "Желаемый срок, итоговый срок<br />может отличатся в зависимости<br />от решения банка",
        badOtp: "Код был введен неверно,<br />у вас еще есть - {counter}",
        rangeMonths:
          "Хотя бы один месяц оплаты должен быть не позже 6 месяцев с момента отгрузки / продажи",
        includes: "Включает в себя:",
        typeFinancing: {
          FL: "Стандартная программа финансирования.",
          FL_579: 'Программа финансирования "Доступные кредиты 5-7-9%".',
          CR: "Стандартная программа финансирования.",
          CR_579: 'Программа финансирования "Доступные кредиты 5-7-9%".',
          CR_0:
            "Финансирование субъектов предпринимательства для <br /> предотвращения российской агрессии и преодоления ее последствий",
          FL_7: "Выручка по Клиенту в эквиваленте до 10 млн евро",
          FL_9: "Выручка по Клиенту в эквиваленте более 10 млн евро",
          CR_7: "Выручка по Клиенту в эквиваленте до 10 млн евро",
          CR_9: "Выручка по Клиенту в эквиваленте более 10 млн евро"
        },
        stateGuarantee:
          "В настоящее время финансирование доступно только по программе Государственных гарантий. Определите и укажите процентную ставку для расчета по формуле: UIRD (3m) + 7% годовых, данные UIRD можно получить по ссылке",
        rate: "Указанная рассчитанная ставка по формуле {UIRD}.",
        individualConditionsDescr:
          "Указывается только если по клиенту Банком согласованы индивидуальные условия или в приложении не реализованы/учтены не все условия платности по Государственным программам/Акционным программам от банка/Другим программам (Указывается только по согласованию с Банком или сотрудником Банка если есть особые условия вышеперечисленные и не предусмотренные) автоматическим расчетом)"
      },
      message: {
        NRM:
          "На данный момент банк не может предоставить {program} этому клиенту с авансом в {percent}%, пожалуйста выберите вариант из доступных ниже",
        percent: "Процентная ставка",
        program: {
          FL: "лизинг",
          CR: "кредит"
        }
      },
      columns: {
        term: "Срок",
        sumFunding: "Сумма финансирования, грн",
        totalFunding: "Общая сумма финансирования, грн",
        prepaymentSumPFReg: "Общий авансовый взнос, грн",
        prepayment: "Аванс, грн",
        rate: "Процентная ставка",
        totalCostPFReg: "Общая стоимость имущества, грн",
        onceCommission: "Размер разовой комиссии, грн"
      },
      total: {
        total: "Итог",
        subject_2: "предмета лизинга",
        subject_5: "предметов лизинга",
        totalCostPFReg: "Общая стоимость сделки:",
        totalCost: "стоимость имущества:",
        totalReg: "затраты на регистрацию:",
        totalPF:
          "расходы по сбору на обязательное государственное пенсионное страхование:",
        prepayment: "Авансовый взнос:",
        prepayment579: "Общий авансовый взнос:",
        totalFunding: "Общая сумма финансирования:",
        lowerPercent: "Скидка для банка:",
        monthCommission: "Ежемесячная комиссия:"
      }
    },
    archive: {
      title: "Архив заявок"
    },
    statistic: {
      title: {
        application: "Заявки",
        bestEmployees: "Лучшие сотрудники",
        bestCompanies: "Лучшие компании",
        bestSalons: "Лучшие салоны",
        applicationCreating: "Создание заявки",
        bySum: "По сумам, грн"
      },
      period: "Період",
      inTheContext: "В разрезе заявок",
      statuses: {
        statuses: "Статусов",
        all: "Все",
        new: "Новых заявок",
        approving: "Утвержденных заявок",
        done: "Выдан кредит"
      },
      salon: "Салон",
      allSalon: "Все салоны",
      table: {
        company: "Компания",
        employee: "Сотрудник",
        numberOfApplication: "Количество заявок",
        amountOfApplication: "Заявок на сумму в грн",
        applicationsForWhichIssuedLeasing: "Заявок по которым выдан лизинг",
        applicationsForWhichIssuedCredit:
          "Заявок по которым выдан кредит на сумму в грн"
      },
      company: "Компания",
      companyOrEmployees: "Компании/Сотрудники",
      companies: "Компании",
      employees: "Сотрудники",
      allEmployees: "Все сотрудники",
      allCompanies: "Все компании",
      appSumInBank: "сумма заявок с сформированными КП переданные в банк",
      sumSumInBank: "сумма аванса по сформированным КП переданные в банк",
      sumSumOutBank: "сумма аванса по сформированным КП, не переданы в банк",
      appSumOutBank: "сумма заявок с сформированными КП, не переданы в банк",
      range: {
        days: "Дни",
        month: "Месяц",
        range: "Диапазон"
      },
      emptyData: "Нет данных"
    },
    settings: {
      title: {
        manageCompany: "Управление компанией",
        "company/add": "Добавление новой компании",
        "company/edit": "Редактирование компании",
        "employee/add": "Добавление сотрудника",
        "employee/edit": "Редактирование сотрудника",
        "store/add": "Добавление салона",
        "store/edit": "Редактирование салона",
        companies: "Выберите компанию"
      },
      company: {
        employeeCount: "Сотрудники",
        storeCount: "Салоны",
        cpOnWeek: "Заявки за неделю",
        cpCount: "Заявки",
        step_1: "Шаг {step} из 2 - ЕГРПОУ / ИНН",
        step_2: "Шаг {step} с 2 - адрес и параметры",
        availablePrograms: "Доступные программы финансирования",
        availableCategories: "Доступные категории",
        companyEmpty: "Добавьте директора и как минимум один салон"
      },
      alert: {
        msgNeedUpdateStatus:
          "В вашей компании есть сотрудники которым необходимо обновить активацию учетной записи.",
        msgChip: "Сотрудники - необходимо обновить активацию",
        badAddress:
          "Добавление компаний расположенных на территории Автономной Ресубликы Крым и в зоне АТО недоступно. Но вы можете вручную изменить адрес в случае если он не действителен."
      },
      columns: {
        role: "Должность",
        address: "Адрес",
        fio: "ФИО",
        status: "Статус",
        actualDt: "Активен до",
        id: "ID",
        name: "Название",
        manager: "Менеджер салона"
      },
      status: {
        ACTUAL: "Активный",
        NOT_ACTUAL: "Не активный",
        BLOCK_GO: "Заблокирован банком",
        LOCK_BOSS: "Заблокирован",
        FIRED: "Удаленный"
      },
      tooltip: {
        isActualEndsSoon:
          "Заканчивается срок активации!<br/>Учетная запись будет заблокирована!",
        isActualEndsSoonAndBlocked:
          "Заканчивается срок активации!<br/>Учетная запись заблокирована!",
        notActual: "Закончился срок активации!<br/>Активируйте учетную запись."
      },
      notEmployees: "У вас нет сотрудников",
      notStores: "У вас нет салонов"
    },
    title: {
      auth: "Авторизация",
      agreement: "Пользовательское соглашение",
      footer: "Менеджер от банка обслуживающий ваше предприятие:"
    },
    nav: {
      title: "Кабинет поставщика",
      offer: "Создать заявку",
      orders: "Заявки",
      archive: "Архив заявок",
      statistic: "Статистика",
      settings: "Управление",
      logout: "Выход",
      staff: "Сотрудники",
      salon: "Салоны"
    },
    label: {
      choose: "Выберите",
      chooseSalon: "Выберите салон",
      chooseRegion: "Выберите область",
      chooseLang: "Выбор языка",
      search: "Поиск",
      searchByTable: "Поиск по таблице",
      searchByEmployees: "Поиск по сотрудникам",
      searchBySalons: "Поиск по салонам",
      onPage: "На странице",
      specFile: "Файл спецификаций",
      okpo: "ЕГРПОУ / ИНН",
      okpo_client: "ЕГРПОУ / ИНН клиента",
      okpo_holder: "ЕГРПОУ / ИНН клиента (8-10 знаков)",
      clientNear: "Клиент рядом - ",
      false: "Нет",
      true: "Да",
      phone: "Номер телефона",
      email: "Выберите E-mail",
      otp: "Введите код из сообщения",
      type: "Тип",
      brand: "Марка",
      model: "Модель",
      specification: "Файл спецификаций",
      equipment: "Комплектация",
      equipment_holder: "Комплектация (перечень через запятую)",
      cost: "Стоимость (в грн)",
      cost_holder: "Стоимость (сумма в грн)",
      lowerPercent: "Скидка для банка в %",
      lowerMoney: "Скидка для банка в грн",
      prepayment: "Аванс в %",
      term: "Срок",
      schedule: "График погашения",
      adaptMonths: "Месяцы погашения",
      rate: "Ставка",
      withOutSpecFile: "отправить заявку в банк без спецификаций",
      withOutMeeting: "відправити заявку в банк без створення зустрічі",
      firstPaymentDate: "Дата отгрузки / продажи",
      fileFormat:
        "Допустимые форматы для загрузки: PDF, JPG, PNG, ODT, DOC, DOCX, XLS, XLSX, ZIP, RAR. Максимальный объемом 5 мб.",
      sortByText: "Сортировать по",
      position: "Должность",
      store: "Салон",
      fio: "ФИО",
      inn: "ИНН",
      leasingSubject: "Предмет лизинга",
      company: "Компания",
      stores: "Салоны",
      period: "Период",
      periodFromTo: "Период с ~ по",
      state: "Статус",
      name: "Название",
      nameLatin: "Название на латинице (для SMS)",
      support: "Сотрудник поддержки",
      supportLdap: "Менеджер от банка",
      otpRequired: "Использовать НРМ только с ОТП",
      address: "Адрес",
      mainOffice: "Главный офис",
      area: "Область/город",
      region: "Район/город",
      city: "Город/село",
      street: "Улица/проспект/переулок/набережная",
      building: "Дом",
      corpus: "Корпус",
      flat: "Квартира",
      changeQuery: "Список пуст. Измените поисковый запрос.",
      listEmpty: "Список пуст.",
      addBuilding: "Добавить дом...",
      matrix: "№ матрицы",
      comment: "Комментарий ГО",
      notRequired: "(необязательное поле)",
      orders: "Заявки",
      sellerNeed: "Указать дилера",
      chooseSeller: "Выберите дилера",
      seller: "Дилер",
      meetingClient: "ID ЕКБ клиента на встрече",
      meetingDate: "Дата встречи",
      meetingTime: "Время встречи",
      registration:
        "Услуги банка по государственной регистрации имущества, по НДС, кроме спецтехники регистрируемой Государственной службой Украины по вопросам труда ({sumReg} грн)",
      pensionFund:
        "Расходы по сбору на обязательное государственное пенсионное страхование при первой регистрации легковых автомобилей по НДС",
      onceCommission: "Разовая комиссия",
      leasingSubType: "Подтип имущества",
      financingProgram: "Фин.программа",
      urid: "Выручка клиента",
      typeCompensation: "Вид начисления",
      percentCompensation: "Процент компенсации",
      indRate: "Индивидуальная ставка",
      indRateValue: "ставка в %",
      indCommission: "Индивидуальная комиссия",
      indCommissionValue: "комиссия в %",
      typesAccrual: {
        sum: "от суммы финансирования",
        cost: "от стоимости"
      }
    },
    placeholder: {
      support: "Введите фамилию или ldap (мин. 5 символов)",
      seller: "Введите ЕГРПОУ / ИНН (8-10 цифр)"
    },
    rules: {
      email: {
        choose: "Выберите E-mail",
        required: "Введите E-mail",
        correct: "Введите корретный E-mail"
      },
      password: {
        required: "Введите пароль"
      },
      phone: {
        choose: "Выберите номер",
        required: "Введите номер",
        correct: "Введите корректный номер +380670001122"
      },
      codeOtp: {
        required: "Введите код ****",
        correct: "Некорректный код",
        invalid: "Неверный код",
        badOtp: "Код был введен неверно, у вас еще есть - {counter}"
      },
      okpo: {
        required: "Введите ЕГРПОУ / ИНН",
        correct: "ЕГРПОУ / ИНН должен состоять из 8-10 цифр"
      },
      length: {
        min_2: "Минимум 2 символа",
        max_50: "Максимум 50 символов",
        max_255: "Максимум 255 символов"
      },
      type: "Выберите предмет лизинга",
      file: {
        max_size: "Максимальной размер файла 5MB",
        bad_type: "Неверный формат файла"
      },
      cost: {
        required: "Введите стоимость",
        correct: "Введите сумму 10000.00",
        max: "Максимум 100000000.00"
      },
      lowerPercent: {
        correct: "Введите скидку (от 0.01 до 99.99)"
      },
      lowerMoney: {
        correct: "Введите сумму 10000.00"
      },
      prepayment: "Выберите первоначальный взнос",
      term: "Выберите срок кредитования",
      schedule: "Выберите график погашения",
      adaptDetails: {
        required: "Выберите месяцы погашения",
        min: "Минимум 2 месяца",
        max: "Максимум 6 месяцев"
      },
      fio: {
        min_max_correct: "Минимум 3 символа (а-яА-ЯёЁЇїІіЄєҐґ-`'\")"
      },
      inn: {
        correct: "Некорректный ввода (ИНН содержит 10 цифр)"
      },
      required: "Это поле является обязательным",
      MIN_MAX_NAME: "Минимум 3 символа (a-zA-Zа-яА-ЯёЁЇїІіЄєҐ0-9._-`'\"())",
      MIN_MAX_TRANSLATE_NAME: "Минимум 3 символа (a-zA-Z0-9._-)",
      isNotNumber: "Некорректный ввода (допустимые символы 1-9)"
    },
    options: {
      lang: {
        ru: "Русский",
        uk: "Украинский"
      },
      objectDetails: {
        private_auto: "Легковые автомобили",
        commercial_auto: "Коммерческий транспорт",
        special_equipment: "Спецтехника",
        attached_agricultural_machinery: "Навесная сельхозтехника",
        self_propelled_agricultural_machinery: "Самоходная сельхозтехника",
        equipment: "Оборудование",
        commercial_auto_less_1:
          "Коммерческие авто грузоподъемностью до 1 тонны",
        st_jcb: "Спецтехника JCB"
      },
      conditionTypes: {
        NEW: "Новое",
        SECOND_HAND: "Б/У"
      },
      schedules: {
        CLASSIC: "Равными частями",
        ANNUITY: "Аннуитет",
        CUSTOM: "Адаптивный"
      },
      month12: "месяцев",
      month24: "месяца",
      // month
      adaptDetails: {
        JAN: "Январь",
        FEB: "Февраль",
        MAR: "Март",
        APR: "Апрель",
        MAY: "Май",
        JUN: "Июнь",
        JUL: "Июль",
        AUG: "Август",
        SEP: "Сентябрь",
        OCT: "Октябрь",
        NOV: "Ноябрь",
        DEC: "Декабрь"
      },
      annual: "% годовых",
      allStores: "Все салоны",
      positions: {
        HEAD: "Директор компании",
        SUB_HEAD: "Зам.директора компании",
        DIRECTOR: "Директор салона",
        SUB_DIRECTOR: "зам.директора салона",
        MANAGER: "Менеджер"
      },
      cpType: {
        dealer: "дилеров",
        employee: "сотрудников"
      },
      allTypeFinancing: {
        FL: "Финансовый лизинг",
        FL_579: "Финансовый лизинг 5-7-9%"
      },
      program579s: {
        FL_7: "Кредит с компенсацией под 7% (выручка до 50 млн грн.)",
        FL_9: "Кредит с компенсацией под 9% (выручка от 50 млн. грн.)"
      }
    },
    action: {
      search: "Найти",
      create: "Создать",
      createRule: "Добавить новое правило",
      update: "Обновить",
      edit: "Редактировать",
      copy: "Копировать",
      save: "Сохранить",
      cancel: "Отмена",
      delete: "Удалить",
      remove: "Удалить",
      choose: "Выберать",
      filter: "Фильтровать",
      send: "Отправить",
      close: "Закрыть",
      continue: "Продолжить",
      clear: "Очистить",
      hide: "Скрыть",
      show: "Показать",
      logout: "Выход",
      back: "Назад",
      clearFilter: "Очистить фильтр",
      exit: "Выход",
      enter: "Войти",
      createOffer: "Создать заявку",
      openAction: "Открыть меню",
      confirm: "Подтвердить",
      backMain: "На главную",
      sendToEmail: "Отправить КП на почту",
      sendToBank: "Отправить заявку в банк",
      agreement: "Принять и продолжить",
      complete: "Завершить",
      retry: "Повторить",
      desire: "Сформировать предложение",
      reboot: "Перезагрузить",
      archive: "Отправить в архив",
      showCpId: "Показать ID заявки",
      closeOffer: "Отменить",
      addSubject: "Добавить еще предмет лизинга",
      addCompany: "Добавить компанию",
      addEmployee: "Добавить сотрудника",
      addSalon: "Добавить салон",
      unlock: "Разблокировать",
      unblock: "Разблокировать",
      lock: "Заблокировать",
      block: "Заблокировать",
      activate: "Активировать",
      reactivate: "Обновить активацию",
      add: "Добавить",
      open: "Открыть",
      history: "История",
      enterManually: "Ввести вручную",
      addrFromEkb: "Адрес из ЕКБ",
      download: "Скачать",
      downloadXLS: "Скачать XLS",
      sendForProcessing: "Отправить в обработку",
      reMakeMeeting: "Создать встречу"
    },
    confirm: {
      delete: "Вы точно хотите удалить?",
      attention: {
        title: "Внимание!",
        not_save_data: "Введенные вами данные НЕ будут сохранены.",
        close_offer: "Отменить создание заявки?",
        confirm_logout: "Выйти из приложения?"
      },
      remove: {
        title: "Удалить сотрудника?",
        msg:
          "После удаления вы сможете повторно добавить сотрудника, но он больше не сможет увидеть свои заявки.\n" +
          "Заявки сотрудника останутся доступны в интерфейсе"
      },
      lock: {
        title: "Заблокировать сотрудника?",
        msg:
          "Сотруднику будет закрыт доступ в приложение. <br/>" +
          "Его заявки останутся доступны в интерфейсе. <br/>" +
          "При необходимости вы самостоятельно сможете разблокировать сотрудника."
      },
      unlock: {
        title: "Розаблокуваты сотрудника?",
        msg:
          "Сотруднику будет открыт доступ в приложение, " +
          "где он сможет полноценно работать создавая новые заявки. <br/>" +
          "При необходимости вы самостоятельно сможете зблокировать сотрудника."
      },
      block: {
        title: "Заблокировать сотрудника?",
        msg:
          "Сотруднику будет закрыт доступ в приложение. <br/>" +
          "Его заявки останутся доступны в интерфейсе. <br/>" +
          "При необходимости вы сможете разблокировать сотрудника."
      },
      unblock: {
        title: "Розаблокуваты сотрудника?",
        msg:
          "Сотруднику будет открыт доступ в приложение, " +
          "где он сможет полноценно работать создавая новые заявки. <br/>" +
          "При необходимости вы сможете зблокировать сотрудника."
      }
    },
    success: {
      logout: "Вы удачно вышли из системы.",
      sendAttachFile: "Файл спецификаций успешно отправлен!",
      sendToEmail:
        "Событие создано, на обработке отправления. Вы можете загрузить предложение и отправить клиенту самостоятельно.",
      sendOfferToBank: "Заявку успешно отправлено в банк!",
      addEmployee: "Сотрудник успешно создан!",
      updateEmployee: "Данные о сотруднике успешно обновлены!",
      removeEmployee: "Сотрудник успешно удален!",
      activeEmployee: "Сотрудник успешно активирован!",
      lockEmployee: "Сотрудник успешно заблокирован!",
      blockEmployee: "Сотрудник успешно заблокирован!",
      unlockEmployee: "Сотрудник успешно разблокирован!",
      unblockEmployee: "Сотрудник успешно разблокирован!",
      addOrg: "Компания успешно создана!",
      updateOrg: "Данные о компании успешно обновлены!",
      addStore: "Салон успешно создан!",
      updateStore: "Данные о салоне успешно обновлены!",
      addCompany: "Компания успешно создана!",
      updateCompany: "Данные компании успешно обновленны!",
      reMakeMeeting: "Встреча успешно создана!"
    },
    warning: {
      sessionOut: "Время сессии вышло! Авторизуйтесь заново."
    },
    error: {
      auth: "К сожалению, произошла ошибка авторизации!",
      ERROR_AUTH_SITE: "К сожалению, произошла ошибка авторизации!",
      ERROR_USER:
        "К сожалению, произошла ошибка при получении данных пользователя!",
      GET_DATA: "К сожалению, произошла ошибка при получении данных!",
      initClient: "К сожалению, произошла ошибка при получении данных клиента!",
      sendPhone: "К сожалению, произошла ошибка при отправке телефона!",
      sendOtp: "К сожалению, произошла ошибка при отправке кода!",
      GAME_OVER_ATTEMPTS:
        "К сожалению, произошла ошибка. Код был введен неверно трижды!",
      GAME_OVER_TIME:
        "К сожалению, произошла ошибка. Время отведенное на ввод кода истекло.",
      createOffer: "К сожалению, произошла ошибка при обработке данных заявки!",
      sendAttachFile:
        "К сожалению, произошла ошибка при отправке файла спецификаций!",
      sendToEmail:
        "К сожалению, произошла ошибка при отправке. Вы можете загрузить предложение и отправить клиенту самостоятельно.",
      sendOfferToBank: "К сожалению, произошла ошибка при отправке КП в банк",
      getDefaultOptions: "К сожалению, произошла ошибка при загрузке данных!",
      other: ERROR_SOMETHING_RU,
      loadPdf: "К сожалению, произошла ошибка при загрузке файла пдф.",
      500: ERROR_SOMETHING_RU,
      404: "Страница не найдена :(",
      403: "Доступ запрещен! Обратитесь к сотрудникам ПриватБанка.",
      401: "Время сессии завершен! Авторизуйтесь снова.",
      400: ERROR_SOMETHING_RU,
      EKB_SERVICE_ERROR: ERROR_SOMETHING_RU,
      EKB_BAD_BINDS: `Ошибка данных клиента, ${CONTACT_MANAGER_RU}`,
      EKB_NO_BINDS: `Клиента не найдено, ${CONTACT_MANAGER_RU}`,
      EKB_SERVICE_BAD_DATA: ERROR_SOMETHING_RU,
      EKB_SERVICE_CLIENT_IS_PHYS:
        "По имеющейся информации, Клиент не зарегистрирован как физическое лицо-предприниматель.",
      CP_ID_NOT_FOUND: "Не найдены данные для указанного ID КП!",
      RESTRICTIONS_AGE:
        "К сожалению услуга недоступна. Дата регистрации Предприятия менее 12 месяцев",
      RESTRICTIONS_STOPS:
        "К сожалению, услуга автоматического расчета для Клиента недоступна. Вы можете обратиться к закрепленному за Вами менеджера от Банка с целью дополнительного анализа возможности / невозможности оформления.",
      MSB_JOURNAL_ERROR: ERROR_SOMETHING_RU,
      MSB_JOURNAL_DUPLICATE:
        "По клиенту уже есть активная заявка на оформление лизинга в Банке, для возможности подачи новой заявки можно получить у закрепленного менеджера от Банка.",
      ECA_ERROR: ERROR_SOMETHING_RU,
      DB_ERROR: ERROR_SOMETHING_RU,
      INVALID_USER_BINDS: `Ошибка учетной записи ${CONTACT_MANAGER_RU}`,
      LEASING_OBJECT_ID_IS_NULL: "Обязательный параметр для заполнения",
      CONDITION_FLAG_IS_NULL: "Обязательный параметр для заполнения",
      FIELD_COST: "Обязательный параметр для заполнения",
      EMPTY_FIELD:
        "Незаполненные обязательные параметры заявки, проверьте пожалуйста!",
      SELL_DATE:
        "Не вказанан дата или она в прошлом. Укажите ориентированную дату оформления / отгрузки товара.",
      CUSTOM_SCHEDULE_MONTH_COUNT:
        "Выберите мин. 2 макс. 6 месяцев погашения основного долга.",
      CUSTOM_SCHEDULE_MORE_6_MONTH:
        "При адаптированном графике, первый платеж должен быть не позднее 6 месяцев с даты оформления.",
      INTEREST_RATE_GETTING_ERROR: ERROR_SOMETHING_RU,
      PDF_CREATION_ERROR: ERROR_SOMETHING_RU,
      CALCULATOR_ERROR: ERROR_SOMETHING_RU,
      SERVICE_CRM_ERROR:
        "Заявка в кредитном журнале создана. Возникла ошибка формирования сервисной встречи по сбору документов.",
      UPLOAD_FILE_ERROR: "Ошибка сохранения файла.",
      SHP_API_ERROR: ERROR_SOMETHING_RU,
      PERSONAL_SERVICE_ERROR: "Ошибка поиска закрепленного от Банка менеджера.",
      SERVICE_LIMIT_ERROR: ERROR_SOMETHING_RU,
      SERVICE_STOP_ERROR: ERROR_SOMETHING_RU,
      HTTP_API_ERROR: ERROR_SOMETHING_RU,
      SEND_MAIL_ERROR:
        "К сожалению, что-то пошло не так. Попробуйте еще раз позже или загрузите предложение и самостоятельно отправьте на почту клиенту.",
      FILE_EMPTY_ERROR: ERROR_SOMETHING_RU,
      FILE_SIZE_ERROR: "Превышен допустимый объем в 5мВ.",
      FILE_EXT_ERROR: "Неверный формат файла.",
      WRONG_AUTH_USER: "Неверный логин или пароль",
      CONFIRM_API_ERROR: "К сожалению, произошла ошибка при отправке сообщения",
      WRONG_PHONE_NUMBER_FORMAT: "Неверный формат телефона (+380ХХYYYYYYY)",
      NOT_EXISTS_USER: "Пользователь с номером {phone} не найден.",
      BLOCK_AUTH_USER:
        "Пользователь заблокирован! Обратитесь к своему руководителю/закрепленному менеджеру от банка",
      LOCK_AUTH_USER:
        "Пользователь заблокирован, обратитесь к своему руководителю.",
      NON_ACTUAL_USER:
        "Пользователя не актуализирован, обратитесь к своему руководителю.",

      TOTAL_CHECKOUT_DATA_ERROR: INCORRECT_DATA_RU,
      PHONE_ALREADY_EXISTS: "Уже есть пользователь с данным номером телефона.",
      ADD_EMPLOYEE_VALIDATE_ERROR: INCORRECT_DATA_RU,
      EDIT_EMPLOYEE_VALIDATE_ERROR: INCORRECT_DATA_RU,
      EDIT_EMPLOYEE_ALL_FIELDS_IDENTICAL:
        "Неудачное редактирования все поля идентичны.",
      EDIT_EMPLOYEE_INVALID_INN: "невалидный ИНН",
      NOT_VALID_FIELD: INCORRECT_DATA_RU,
      NOT_EXIST_STORE: "Несуществующее организация в БД.",
      STORE_HO_VALIDATION: INCORRECT_DATA_RU,
      STORE_BAD_MANAGER_LDAP: "Пустой или некорректный ldap менеджера",
      STORE_MANAGER_LDAP_NOT_FOUND: "Такого менеджера не существует",
      STORE_ALL_FIELDS_IDENTICAL:
        "Неудачное редактирования все поля идентичны.",
      NOT_EXIST_ORG: "Неіснуюча організація в БД",
      ALREADY_EXIST_ORG: "Організація з таким ЕДРПОУ вже існує",
      ORG_HO_VALIDATION: INCORRECT_DATA_RU,

      ORG_SUPPORT_LDAP: "Не найдено сотрудника поддержки.",
      ORG_EMPTY_OBJ_TYPES:
        "Некорректно указана категория лизинга (пустая или неправильное значение).",
      NO_ATTEMPTS_OTP: "Исчерпаны попытки введения ОТП пароля.",
      CHECK_INSIDERS_LITE_ERROR: "Ошибка API сервиса Инсайдеров",
      ORG_LOCKED:
        "По организации обнаружены ограничения в доступе, обратитесь за консультацией к своему менеджеру от банка.",
      ORG_BAD_NAME: "Организация не найдена / некорректные данные.",
      ORG_BAD_SMS_NAME: "Некорректная название организации латиницей (для СМС)",
      ORG_EMPTY_ADDR: "Не указано ID адреса.",
      ADDR_API_ERROR: "Возникла ошибка сервиса адресов, попробуйте позже.",
      ADDR_RESTRICTION:
        "C этим адресом работать не возможно - он относиться к группе запрещенных!",
      NOT_ESA_ADMIN_ACCESS: "Доступ запрещен.",
      UNKNOWN_ERROR: ERROR_SOMETHING_RU,

      addEmployee: "Ошибка при добавлении сотрудника!",
      updateEmployee: "Ошибка при редактировании сотрудника!",
      activateEmployee: "Ошибка при активации сотрудника!",
      lockEmployee: "Ошибка при блокировке сотрудника!",
      blockEmployee: "Ошибка при блокировке сотрудника!",
      unlockEmployee: "Ошибка при разблокировке сотрудника!",
      unblockEmployee: "Ошибка при разблокировке сотрудника!",
      addOrg: "Ошибка при добавлении компании!",
      updateOrg: "Ошибка при редактировании компании!",
      addStore: "Ошибка при добавлении салона!",
      updateStore: "Ошибка при редактировании салона!",
      addCompany: "Ошибка при добавлении компании!",
      updateCompany: "Ошибка при редактировании компании!",
      calcPledgeDetails: ERROR_SOMETHING_RU,
      isActualEndsSoon:
        "Внимание! У вас заканчивается срок активации. Обратитесь к своему руководителю иначе аккаунт будет заблокирован!",

      FILE_REPORT_ERROR: "При выгрузке отчета в файл возникла ошибка",
      DEALER_INFO_NOT_FOUND_BY_OKPO: "Информация о диллере по ОКПО не найдена!",
      SELLER_OKPO_FORMAT:
        "ОКПО продавца передан в некорректном виде (онли цифры, длина 8 - 10)",
      SELLER_SEARCH_ERROR:
        "Случилась ошибка при поиске данных по окпо продавца",
      SELLER_ORG_LOCKED: "Организация продавца заблокирована",
      SELLER_INFO_CP_ERROR:
        "В предварительной КП не была найдена информация о продавце",
      SELLER_ADD_ILLEGAL_CP_STATE_ERROR:
        "Статус КП не позволяет указать ОКПО продавца",
      WRONG_OPERATION: ERROR_SYSTEM_RU,
      DEPAR_SUBORD_GETTING_ERROR: "Организация продавца заблокирована",
      NOT_ESA_USER_ACCESS: "Нет доступа к интерфейсу у еса пользователя",
      MSB_JOURNAL_EDIT_ERROR:
        "Заявка в кредитном журнале создана и сформирована встречу. Возникла ошибка обновления данных по встрече в журнале.",
      EKB_LINK_ERROR: "Ошибка получения связанных лиц с предприятием",
      SERVICE_TASK_DOC_ERROR:
        "Заявка в кредитном журнале создана. Возникла ошибка формирования задания на сбор документов.",
      WEB_REQUEST_DATA_INVALID:
        "Вы выбрали время встречи в прошлом. Выберите новое время встречи.",
      WRONG_CHOOSE_DATE:
        "Период указан неправильно, выберите дату с интервалом минимум {range}.",
      rangeOneDay: "1 день",
      rangeOneMonth: "1 месяц"
    },
    app: {
      attempt: "попытка",
      attempts: "попытки"
    },
    $vuetify: {
      badge: "знак",
      close: "Закрыть",
      dataIterator: {
        noResultsText: "Не найдено подходящих записей",
        loadingText: "Запись загружается..."
      },
      dataTable: {
        itemsPerPageText: "Строк на странице:",
        ariaLabel: {
          sortDescending: "Упорядочено по убыванию.",
          sortAscending: "Упорядочено по возрастанию.",
          sortNone: "Не упорядочено.",
          activateNone: "Активируйте, чтобы убрать сортировку.",
          activateDescending: "Активируйте для упорядочивания убыванию.",
          activateAscending: "Активируйте для упорядочивания по возрастанию."
        },
        sortBy: "Сортировать по"
      },
      dataFooter: {
        itemsPerPageText: "Записей на странице:",
        itemsPerPageAll: "Все",
        nextPage: "Следующая страница",
        prevPage: "Предыдущая страница",
        firstPage: "Первая страница",
        lastPage: "Последняя страница",
        pageText: "{0}-{1} из {2}"
      },
      datePicker: {
        itemsSelected: "{0} выбран",
        nextMonthAriaLabel: "Следующий месяц",
        nextYearAriaLabel: "Следующий год",
        prevMonthAriaLabel: "Прошлый месяц",
        prevYearAriaLabel: "Предыдущий год"
      },
      noDataText: "Отсутствуют данные",
      carousel: {
        prev: "Предыдущий слайд",
        next: "Следующий слайд",
        ariaLabel: {
          delimiter: "Слайд {0} из {1}"
        }
      },
      calendar: {
        moreEvents: "Еще {0}"
      },
      fileInput: {
        counter: "Файлов: {0}",
        counterSize: "Файлов: {0} (всего {1})"
      },
      timePicker: {
        am: "AM",
        pm: "PM"
      },
      pagination: {
        ariaLabel: {
          wrapper: "Навигация по страницам",
          next: "Следующая страница",
          previous: "Предыдущая страница",
          page: "Перейти на страницу {0}",
          currentPage: "Текущая страница, Страница {0}"
        }
      }
    }
  },
  uk: {
    load: "Завантаження... Зачекайте будь ласка",
    home: {
      title: "Доброго дня, {user}!",
      text: {
        welcome: "Ласкаво просимо до фінансового лізингу",
        managerHelp: {
          $1: "Щоб отримати допомогу з програмою фінансового лізингу,",
          $2: "звертайтесь до менеджера нашого банку"
        },
        settings: "Налаштування"
      }
    },
    auth: {
      title: "Авторизація",
      enterPhone:
        "Введіть номер на який буде надіслано SMS з одноразовим паролем для входу",
      enterOtp:
        "На номер {tel} відправлено повідомлення з кодом для авторизації"
    },
    orders: {
      title: "Заявки в роботі",
      title_pdf: "Перегляд пропозиції",
      notOrders: "У вас немає активних заявок",
      columns: {
        crDt: "Дата",
        orgName: "Компанія",
        okpo: "ЄДРПОУ/ІПН",
        cost: "Сума",
        term: "Термін",
        status: "Статус",
        pledgeQuantity: "Кіл.заст",
        leasingSubject: "Предмети лізингу",
        estateSubject: "Предмети кредитування",
        type: "Тип",
        condition: "Стан",
        brand: "Бренд",
        model: "Марка",
        lowerPercent: "Знижка для банку",
        cpAuthor: "Автор",
        seller: "Постачальник",
        client: "Клієнт"
      },
      state: {
        CP_SAVED: "Збережена",
        JOURNAL_ACCEPTED: "Прийнята банком",
        JOURNAL_DUPLICATE: "Дублікат заявки",
        JOURNAL_ERROR: "Не прийнята банком"
      }
    },
    offer: {
      title: "Створення заявки",
      subTitle: {
        subject: "Предмет лізингу",
        estate: "Майно",
        compensationPartner: "Компенсація від партнера",
        general: "Загальні параметри",
        program579s: 'Компенсація "Доступні кредити 5-7-9%"',
        stateGuarantee: "Державна гарантія забезпечення",
        individualConditions: "Індивідуальні умови",
        baseAccrual: "База нарахування:"
      },
      allTypeFinancing: {
        FL: "Фінансовий лізинг",
        FL_579: "Фінансовий лізинг 5-7-9%",
        CR: "Кредит на Основні засоби",
        CR_579: "Кредит на Основні засоби 5-7-9%"
      },
      overlay: {
        title: "Зачекайте, будь ласка",
        dataProcessing: "йде обробка даних",
        individualCalculation: "йде індивідуальний розрахунок пропозиції",
        timeProcessing: "це займає в середньому не більше {time}",
        time_30sec: "30 секунд",
        time_3min: "3 хвилин"
      },
      step: {
        zero: "Оберіть програму фінансування",
        oneDealer: "Крок 1 - вибір / пошук дилера",
        oneProgram: "Крок 1 - оберіть програму фінансування",
        oneClient: "Крок 1 - авторизація клієнта",
        two: "Крок 2 - предмет лізингу та вартість",
        two_CR: "Крок 2 - майно та вартість",
        three: "Крок 3 - вибір варіанта фінансування",
        threeClientNotNear: "Крок 3 - підтвердження заявки",
        four: "Крок 4 - підтвердження заявки",
        finish: "КП успішно створено",
        finish_text_1:
          "Згідно цього розрахунку, подача заявки до банку доступна протягом 2 діб.",
        finish_text_2:
          "За згодою клієнта, ви можете надіслати заявку зараз або через журнал заявок протягом наступних 48 годин.",
        protector_text_1:
          "На жаль у клієнта відсутній ліміт фінансування та заблокована подача заявки.",
        protector_text_2:
          "Можливо це пов'язано з відсутністю/недостатністю фінансового стану або в зв'язку з обмеженнями внаслідок військових дій. Більш детально у співробітника банку."
      },
      attachFile: "Необхідно прикріпити файл специфікацій",
      createMeeting: "Необхідно створити зустріч",
      addressMeeting: "Адреса проведення зустрічі",
      sendToEmail: "Надіслати КП на пошту",
      chooseClientPhone:
        "Виберіть номер на який буде надіслано повідомлення від ПриватБанку з кодом підтвердження",
      tooltip: {
        discount: "Повна вартість для банку за вирахуванням знижки",
        prepayment: "Сума початкового внеску",
        term:
          "Бажаний термін, підсумковий термін<br />може відрізнятись в залежності <br /> від рішення банку",
        badOtp: "Код був введений невірно,<br />у вас ще є - {counter}",
        rangeMonths:
          "Хоча б один місяць оплати повинен бути не пізніше 6 місяців з моменту відвантаження / продажу",
        includes: "Включає в себе:",
        typeFinancing: {
          FL: "Стандартна програма фінансування.",
          FL_579: 'Програма фінансування "Доступні кредити 5-7-9%".',
          CR: "Стандартна програма фінансування.",
          CR_579: 'Програма фінансування "Доступні кредити 5-7-9%".',
          CR_0:
            "Фінансування суб’єктів підприємництва для <br /> запобігання російській агресії та подолання її наслідків",
          FL_7: "Виручка по Клієнту в еквіваленті до 10 млн євро",
          FL_9: "Виручка по Клієнту в еквіваленті понад 10 млн євро",
          CR_7: "Виручка по Клієнту в еквіваленті до 10 млн євро",
          CR_9: "Виручка по Клієнту в еквіваленті понад 10 млн євро"
        },
        stateGuarantee:
          "Наразі фінансування доступне лише за програмою Державних гарантій. Визначте та вкажіть відсоткову ставку для розрахунку за формулою: UIRD (3m) + 7% річних, дані UIRD можливо отримати за посиланням",
        rate: "Вказана розрахована ставка за формулою {UIRD}.",
        individualConditionsDescr:
          "Вказується лише якщо по клієнту Банком погоджені індивідуальні умови або  в додатку не реалізовані/враховані не всі  умови платності за Державними програмами/Акційними програмами від банку/Іншими програмами (Вказується лише за погодженням з Банком або співробітником Банку  якщо є особливі умови перераховані вище та непередбачені автоматичним розрахунком)"
      },
      message: {
        NRM:
          "На даний момент банк не може надати {program} цьому клієнту з авансом в {percent}%, будь ласка виберіть варіант з доступних нижче",
        percent: "Процентна ставка",
        program: {
          FL: "лізинг",
          CR: "кредит"
        }
      },
      columns: {
        term: "Термін",
        sumFunding: "Сума фінансування, грн",
        totalFunding: "Загальна сума фінансування, грн",
        prepaymentSumPFReg: "Загальний авансовий внесок, грн",
        prepayment: "Аванс, грн",
        rate: "Процентна ставка",
        totalCostPFReg: "Загальна вартість майна, грн",
        onceCommission: "Розмір разової комісії, грн"
      },
      total: {
        total: "Підсумок",
        subject_1: "предмет лізингу",
        subject_2: "предмета лізингу",
        subject_5: "предметів лізингу",
        estate: "Кількість майна - {count}",
        totalCostPFReg: "Загальна вартість угоди:",
        totalCost: "вартість майна:",
        totalReg: "витрати на реєстрацію:",
        totalPF:
          "витрати зі збору на обов’язкове державне пенсійне страхування:",
        prepayment: "Авансовий внесок:",
        prepaymentCR: "Авансовий внесок від вартості майна:",
        prepayment579: "Загальний авансовий внесок:",
        totalFunding: "Загальна сума фінансування:",
        lowerPercent: "Знижка для банку:",
        monthCommission: "Щомісячна комісія:"
      }
    },
    archive: {
      title: "Архів заявок"
    },
    statistic: {
      title: {
        application: "Заявки",
        bestEmployees: "Найкращі співробітники",
        bestCompanies: "Найкращі компанії",
        bestSalons: "Найкращі салони",
        applicationCreating: "Створенні заявки",
        bySum: "За сумами, грн"
      },
      period: "Період",
      inTheContext: "В розрізі статусів",
      statuses: {
        statuses: "Статусів",
        all: "Всі",
        new: "Усі заявки",
        saving: "Збережені",
        given: "Подані",
        approving: "Затверджені",
        done: "Видано кредит"
      },
      salon: "Салон",
      allSalon: "Усі салони",
      table: {
        company: "Компанія",
        salon: "Салон",
        employee: "Співробітник",
        totalAmountWithSavedCP: "Загальна сума сформованих КП в грн.",
        totalAmountFundingWithSavedCP:
          "Загальна сума фінансування сформованих КП в грн.",
        totalAmountCPSubmittedToBank:
          "Згальна сума сформованих КП переданих до банку в грн.",
        totalAmountFundingCPSubmittedToBank:
          "Згальна сума фінансування сформованих КП переданих до банку в грн.",
        newOwns: "Усі заявки",
        savingOnes: "Збережені",
        givenOnes: "Подані",
        approvingOnes: "Затверджені",
        doneOnes: "Видано кредит",
        nrmCalling: "К-сть звернень в НРМ",
        nrmOrStopRejected: "К-сть відмов НРМ чи сервісу стоп-факторів"
      },
      company: "Компанія",
      companyOrEmployees: "Компанії/Співробітники",
      companies: "Компанії",
      employees: "Співробітники",
      allEmployees: "Всі співробітники",
      allCompanies: "Всі компанії",
      totalAmountWithSavedCP: "Загальна сума сформованих КП",
      totalAmountFundingWithSavedCP:
        "Загальна сума фінансування сформованих КП ",
      totalAmountCPSubmittedToBank:
        "Згальна сума сформованих КП переданих до банку",
      totalAmountFundingCPSubmittedToBank:
        "Згальна сума фінансування сформованих КП переданих до банку",
      range: {
        days: "Дні",
        month: "Місяць",
        range: "Діапазон"
      },
      emptyData: "Немає даних"
    },
    settings: {
      title: {
        manageCompany: "Керування компанією",
        "company/add": "Додавання нової компанії",
        "company/edit": "Редагування компанії",
        "employee/add": "Додавання співробітника",
        "employee/edit": "Редагування співробітника",
        "store/add": "Додавання салону",
        "store/edit": "Редагування салону",
        companies: "Виберіть компанію"
      },
      company: {
        employeeCount: "Співробітники",
        storeCount: "Салони",
        cpOnWeek: "Заявки за тиждень",
        cpCount: "Заявки",
        step_1: "Крок {step} из 2 - ЄДРПОУ / ІПН",
        step_2: "Крок {step} з 2 - адреса та параметри",
        availablePrograms: "Доступні програми фінансування",
        availableCategories: "Доступні категорії",
        companyEmpty: "Додайте директора і як мінімум один салон"
      },
      alert: {
        msgNeedUpdateStatus:
          "У вашій компанії є співробітники яким необхідно оновити активацію облікового запису.",
        msgChip: "Співробітники - необхідно оновити активацію",
        badAddress:
          "Додавання компаній розташованих на території Автономної Ресубліки Крим та в зоні АТО недоступно. Але ви можете вручну змінити адресу в разі якщо вона не дійсний."
      },
      columns: {
        role: "Посада",
        address: "Адреса",
        fio: "ПІБ",
        status: "Статус",
        actualDt: "Активний до",
        id: "ID",
        name: "Назва",
        manager: "Менеджер салону"
      },
      status: {
        ACTUAL: "Активний",
        NOT_ACTUAL: "Не активний",
        BLOCK_GO: "Заблокований банком",
        LOCK_BOSS: "Заблокований",
        FIRED: "Видалений"
      },
      tooltip: {
        isActualEndsSoon:
          "Закінчується термін активації.<br/>Обліковий запис буде заблоковано!",
        isActualEndsSoonAndBlocked:
          "Закінчується термін активації.<br/>Обліковий запис заблоковано!",
        notActual:
          "Закінчився термін активації.<br/>Оновіть активацію облікового запису!"
      },
      notEmployees: "У вас немає співробітників",
      notStores: "У вас немає салонів"
    },
    title: {
      agreement: "Угода користувача",
      footer: "Менеджер від банку обслуговуючий ваше підприємство:"
    },
    nav: {
      title: "Кабінет постачальника",
      offer: "Створити заявку",
      offerCreated: "КП успішно створено",
      orders: "Заявки",
      archive: "Архів заявок",
      statistic: "Статистика",
      settings: "Управління",
      logout: "Вихід",
      staff: "Співробітники",
      salon: "Салони"
    },
    label: {
      choose: "Виберіть",
      chooseSalon: "Виберіть салон",
      chooseRegion: "Виберіть область",
      chooseLang: "Вибір мови",
      search: "Пошук",
      searchByTable: "Пошук по таблиці",
      searchByEmployees: "Пошук по співробітникам",
      searchBySalons: "Пошук по салонам",
      onPage: "На сторінці",
      specFile: "Файл специфікацій",
      okpo: "ЄДРПОУ / ІПН",
      okpo_client: "ЄДРПОУ / ІПН клієнта",
      okpo_holder: "ЄДРПОУ / ІПН клієнта (8-10 знаків)",
      clientNear: "Клієнт поруч - ",
      false: "Ні",
      true: "Так",
      phone: "Номер телефону",
      email: "Виберіть E-mail",
      otp: "Введіть код з повідомлення",
      type: "Тип",
      brand: "Марка",
      model: "Модель",
      specification: "Файл специфікацій",
      equipment: "Комплектація",
      equipment_holder: "Комплектація (перелік через кому)",
      cost: "Вартість (в грн)",
      cost_holder: "Вартість (сума в грн)",
      lowerPercent: "Знижка для банку в %",
      lowerMoney: "Знижка для банку в грн",
      prepayment: "Аванс в %",
      term: "Термін",
      schedule: "Графік погашення",
      adaptMonths: "Місяці погашення",
      rate: "Ставка",
      rateAnnual: "Ставка % ",
      withOutSpecFile: "відправити заявку в банк без специфікацій",
      withOutMeeting: "відправити заявку в банк без створення зустрічі",
      firstPaymentDate: "Дата відвантаження / продажу",
      fileFormat:
        "Допустимі формати до завантаження: PDF, JPG, PNG, ODT, DOC, DOCX, XLS, XLSX, ZIP, RAR. Максимальний об'ємом 5 мб.",
      sortByText: "Сортувати по",
      position: "Посада",
      store: "Салон",
      fio: "ПІБ",
      inn: "ІПН",
      leasingSubject: "Предмет лізингу",
      estateSubject: "Предмет майна",
      company: "Компанія",
      stores: "Салони",
      period: "Період",
      periodFromTo: "Період з ~ по",
      state: "Статус",
      name: "Назва",
      nameLatin: "Назва на латиниці (для SMS)",
      support: "Співробітник підтримки",
      supportLdap: "Менеджер від банку",
      otpRequired: "Використовувати НРМ тільки з ОТП",
      address: "Адреса",
      mainOffice: "Головний офіс",
      area: "Область/місто",
      region: "Район/місто",
      city: "Місто/село/селище",
      street: "Вулиця/проспект/провулок/набережна",
      building: "Будинок",
      corpus: "Корпус",
      flat: "Квартира",
      changeQuery: "Список порожній. Змініть пошуковий запит.",
      listEmpty: "Список порожній.",
      addBuilding: "Додати будинок ...",
      matrix: "№ матриці без комп./знижки",
      matrix_md: "без комп./знижки",
      matrixComp: "№ матриці без 579 є комп./знижки",
      matrixComp_md: "без 579 є комп./знижки",
      matrixComp579: "№ матриці з 579 є комп./знижки",
      matrixComp579_md: "з 579 є комп./знижки",
      comment: "Коментар для ГО",
      notRequired: "(необов'язкове поле)",
      orders: "Заявки",
      sellerNeed: "Вказати дилера",
      chooseSeller: "Виберіть дилера",
      seller: "Дилер",
      meetingClient: "ID ЕКБ клієнта на зустрічі",
      meetingDate: "Дата зустрічі",
      meetingTime: "Час зустрічі",
      registration:
        "Послуги банку з державної реєстрації майна, з ПДВ, крім спецтехніки яка реєструється Державною службою України з питань праці ({sumReg} грн)",
      pensionFund:
        "Витрати зі збору на обов’язкове державне пенсійне страхування під час першої реєстрації легкових автомобілів, з ПДВ",
      onceCommission: "Разова комісія",
      leasingSubType: "Підтип майна",
      financingProgram: "Фін.програма",
      urid: "Виручка клієнта",
      typeCompensation: "Вид нарахування",
      percentCompensation: "Відсоток компенсації",
      indRate: "Індивідуальна ставка",
      indRateValue: "ставка в %",
      indCommission: "Індивідуальна комісія",
      indCommissionValue: "комісія в %",
      typesAccrual: {
        sum: "від суми фінансування",
        cost: "від вартості"
      }
    },
    placeholder: {
      support: "Введіть прізвище або ldap (мін. 5 символів)",
      seller: "Введіть ЄДРПОУ / ІПН (8-10 цифр)"
    },
    rules: {
      email: {
        choose: "Виберіть E-mail",
        required: "Введіть E-mail",
        correct: "Введіть коректний E-mail"
      },
      password: {
        required: "Введіть пароль"
      },
      phone: {
        choose: "Виберіть номер",
        required: "Введіть номер",
        correct: "Введіть коректний номер +380670001122"
      },
      codeOtp: {
        required: "Введіть код ****",
        correct: "Некоректний код",
        invalid: "Невірний код",
        badOtp: "Код був введений невірно, у вас ще є - {counter}"
      },
      okpo: {
        required: "Введіть ЄДРПОУ / ІПН",
        correct: "ЄДРПОУ / ІПН повинен складатися з 8-10 цифр"
      },
      length: {
        min_2: "Мінімум 2 символа",
        max_50: "Максимум 50 символів",
        max_255: "Максимум 255 символів"
      },
      file: {
        max_size: "Максимальній розмір файлу 5MB",
        bad_type: "Неправильний формат файлу"
      },
      cost: {
        required: "Введіть вартість",
        correct: "Введіть суму 100000.00",
        max: "Максимум 100000000.00"
      },
      lowerPercent: {
        correct: "Введіть знижку (від 0.01 до 99.99)"
      },
      lowerMoney: {
        correct: "Введіть суму 10000.00"
      },
      prepayment: "Виберіть початковий внесок",
      term: "Виберіть термін кредитування",
      schedule: "Виберіть графік погашення",
      adaptDetails: {
        required: "Виберіть місяці погашення",
        min: "Мінімум 2 місяці",
        max: "Максимум 6 місяців"
      },
      rate: {
        correct: "Некоректне введення (тільки цифри від 1 до 99.99)"
      },
      fio: {
        min_max_correct: "Мінімум 3 символа (а-яА-ЯёЁЇїІіЄєҐґ-`'\")"
      },
      inn: {
        correct: "Некоректне введення (ІПН містить 10 цифр)"
      },
      required: "Це поле є обовязковим",
      MIN_MAX_NAME: "Мінімум 3 символа (a-zA-Zа-яА-ЯёЁЇїІіЄєҐ0-9._-`'\"())",
      MIN_MAX_TRANSLATE_NAME: "Мінімум 3 символа (a-zA-Z0-9._-)",
      isNotNumber: "Некоректне введення (допустимі символи 1-9)",
      percentComp: {
        correct: "Введіть компенсацію (від 0.01 до 99.99)"
      }
    },
    options: {
      lang: {
        ru: "Російська",
        uk: "Українська"
      },
      objectDetails: {
        private_auto: "Легкові автомобілі",
        commercial_auto: "Комерційний транспорт",
        special_equipment: "Спецтехніка",
        attached_agricultural_machinery: "Навісна сільгосптехніка",
        self_propelled_agricultural_machinery: "Самохідна сільгосптехніка",
        equipment: "Обладнання",
        commercial_auto_less_1: "Комерційні авто вантажопідйомністю до 1 тони",
        st_jcb: "Спецтехніка JCB"
      },
      conditionTypes: {
        NEW: "Нове",
        SECOND_HAND: "Б/В"
      },
      schedules: {
        CLASSIC: "Рівними частинами",
        ANNUITY: "Ануїтет",
        CUSTOM: "Адаптивний"
      },
      month12: "місяців",
      month24: "місяці",
      //month
      adaptDetails: {
        JAN: "Січень",
        FEB: "Лютий",
        MAR: "Березень",
        APR: "Квітень",
        MAY: "Травень",
        JUN: "Червень",
        JUL: "Липень",
        AUG: "Серпень",
        SEP: "Вересень",
        OCT: "Жовтень",
        NOV: "Листопад",
        DEC: "Грудень"
      },
      annual: "% річних",
      allStores: "Всі салони",
      positions: {
        HEAD: "Директор компанії",
        SUB_HEAD: "Зам.директора компанії",
        DIRECTOR: "Директор салону",
        SUB_DIRECTOR: "Зам.директор салону",
        MANAGER: "Менеджер"
      },
      cpType: {
        dealer: "дилерів",
        employee: "співробітників"
      },
      allTypeFinancing: {
        FL: "Фінансовий лізинг",
        FL_579: "Фінансовий лізинг 5-7-9%",
        CR: "Кредит на Основні засоби",
        CR_579: "Кредит на Основні засоби 5-7-9%"
      },
      program579s: {
        FL_5: "Кредит з компенсацією під 5%",
        FL_7: "Кредит з компенсацією під 7%",
        FL_9: "Кредит з компенсацією під 9%",
        FL_11: "Кредит з компенсацією під 11%",
        CR_7: "Кредит з компенсацією під 7%",
        CR_9: "Кредит з компенсацією під 9%",
        CR_11: "Кредит з компенсацією під 11%",
        CR_0: "Кредит з компенсацією під 0% на інвестиційні цілі"
      },
      typesCompensation: {
        COST: "Вартість застави",
        SUM: "Сума фінансування"
      }
    },
    action: {
      search: "Знайти",
      create: "Створити",
      createRule: "Додати нове правило",
      update: "Оновити",
      edit: "Редагувати",
      copy: "Копіювати",
      save: "Зберегти",
      cancel: "Відміна",
      delete: "Видалити",
      remove: "Видалити",
      filter: "Фільтрувати",
      send: "Надіслати",
      close: "Закрити",
      continue: "Продовжити",
      clear: "Очистити",
      hide: "Приховати",
      show: "Показати",
      logout: "Вихід",
      back: "Назад",
      clearFilter: "Очистити фільтр",
      exit: "Вихід",
      enter: "Увійти",
      createOffer: "Створити заявку",
      openAction: "Відкріти меню",
      confirm: "Підтвердити",
      backMain: "На головну",
      sendToEmail: "Надіслати КП на пошту",
      sendToBank: "Надіслати заявку в банк",
      agreement: "Прийняти та продовжити",
      complete: "Завершити",
      retry: "Спробувати знову",
      desire: "Сформувати пропозицію",
      reboot: "Перезавантажити",
      archive: "Відправити в архів",
      showCpId: "Показати ID заявки",
      closeOffer: "Скасувати",
      addSubject: "Додати ще предмет лізингу",
      addEstate: "Додати ще предмет майна",
      addCompany: "Додати компанію",
      addEmployee: "Додати співробітника",
      addSalon: "Додати салон",
      unlock: "Розблокувати",
      unblock: "Розблокувати",
      lock: "Заблокувати",
      block: "Заблокувати",
      activate: "Активувати",
      reactivate: "Оновити активацію",
      add: "Додати",
      open: "Відкрити",
      history: "Історія",
      enterManually: "Ввести вручну",
      addrFromEkb: "Адреса з ЕКБ",
      download: "Завантажити",
      downloadXLS: "Завантажити XLS",
      sendForProcessing: "Відправити в обробку",
      reMakeMeeting: "Створити зустріч"
    },
    confirm: {
      delete: "Ви точно хочете видалити?",
      attention: {
        title: "Увага!",
        not_save_data: "Введені вами дані НЕ будуть збережені. ",
        close_offer: "Скасувати створення заявки?",
        confirm_logout: "Вийти з програми?"
      },
      remove: {
        title: "Видалити співробітника?",
        msg:
          "Після видалення ви зможете повторно додати співробітника, але він більше не зможе побачити свої заявки.<br/>" +
          "Заявки співробітника залишаться доступні в інтерфейсі."
      },
      activate: {
        title: "Активувати співробітника?",
        msg:
          "Доступ у додаток буде відкрито на 90 діб.<br/>" +
          "Ваш співробітник зможе повноцінно працювати створюючи нові заявки."
      },
      reactivate: {
        title: "Оновити активацію?",
        msg:
          "Доступ у додаток буде оновлено на 90 діб.<br/>" +
          "Ваш співробітник зможе повноцінно працювати створюючи нові заявки."
      },
      lock: {
        title: "Заблокувати співробітника?",
        msg:
          "Доступ у додаток буде закритий.<br/>" +
          "Усі заявки співробітника залишаться доступні в інтерфейсі.<br/>" +
          "За потреби ви самостійно зможете розблокувати співробітника."
      },
      unlock: {
        title: "Розблокувати співробітника?",
        msg:
          "Співробітнику буде відкрито доступ у додаток, " +
          "де він зможе повноцінно працювати створюючи нові заявки.<br/>" +
          "За потреби ви самостійно зможете зблокувати співробітника."
      },
      block: {
        title: "Заблокувати співробітника?",
        msg:
          "Доступ у додаток буде закритий.<br/>" +
          "Усі заявки співробітника залишаться доступні в інтерфейсі.<br/>" +
          "За потреби ви зможете розблокувати співробітника."
      },
      unblock: {
        title: "Розблокувати співробітника?",
        msg:
          "Співробітнику буде відкрито доступ у додаток, " +
          "де він зможе повноцінно працювати створюючи нові заявки.<br/>" +
          "За потреби ви зможете зблокувати співробітника."
      }
    },
    success: {
      logout: "Ви вдало вийшли з системи.",
      sendAttachFile: "Файл специфікацій успішно відправлено!",
      sendToEmail:
        "Подія створена, на опрацюванні відправлення. Ви маєте змогу завантажити пропозицію та надіслати клієнтові самостійно.",
      sendOfferToBank: "Заявку успішно відправлено до банку!",
      addEmployee: "Співробітник успішно створений!",
      updateEmployee: "Дані про співробітника успішно оновлені!",
      removeEmployee: "Співробітник успішно видалений!",
      activateEmployee: "Співробітник успішно активований!",
      lockEmployee: "Співробітник успішно заблоковано!",
      blockEmployee: "Співробітник успішно заблоковано!",
      unlockEmployee: "Співробітник успішно розблоковано!",
      unblockEmployee: "Співробітник успішно розблоковано!",
      addOrg: "Компанія успішно створена!",
      updateOrg: "Дані про компанію успішно оновлені!",
      addStore: "Салон успішно створений!",
      updateStore: "Дані про салон успішно оновлені!",
      addCompany: "Компанія успішно створена!",
      updateCompany: "Дані компанії успішно оновлені!",
      reMakeMeeting: "Зустріч успішно створена!"
    },
    warning: {
      sessionOut: "Час сесії завершено! Авторизуйтесь знову."
    },
    error: {
      auth: "На жаль, сталась помилка авторизації!",
      ERROR_AUTH_SITE: "На жаль, сталась помилка авторизації!",
      ERROR_USER: "На жаль, сталась помилка при отриманні даних користувача!",
      GET_DATA: "На жаль, сталась помилка при отриманні даних!",
      initClient: "На жаль, сталась помилка при отриманні даних клієнта!",
      sendPhone: "На жаль, сталась помилка при відправленні телефону!",
      sendOtp: "На жаль, сталась помилка при відправленні коду!",
      GAME_OVER_ATTEMPTS:
        "На жаль, сталась помилка. Код був введений невірно тричі!",
      GAME_OVER_TIME:
        "На жаль, сталась помилка. Час відведений на введення коду минув.",
      createOffer: "На жаль, сталась помилка при обробці даних заявки!",
      sendAttachFile:
        "На жаль, сталась помилка при відправленні файлу специфікацій!",
      sendToEmail:
        "На жаль, сталась помилка при відправленні. Ви маєте змогу завантажити пропозицію та надіслати клієнтові самостійно.",
      sendOfferToBank: "На жаль, сталась помилка при відправленні КП до банку",
      getDefaultOptions: "На жаль, сталася помилка при завантаженні даних!",
      other: ERROR_SOMETHING_UA,
      loadPdf: "На жаль, сталась помилка при завантаженні файлу пдф.",
      500: ERROR_SOMETHING_UA,
      404: "Сторінку не знайдено :(",
      403: "Доступ заборонено! Зверніться до співробітників ПриватБанку.",
      401: "Час сесії завершено! Авторизуйтесь знову.",
      400: ERROR_SOMETHING_UA,
      EKB_SERVICE_ERROR: ERROR_SOMETHING_UA,
      EKB_BAD_BINDS: `Помилка даних клієнта, ${CONTACT_MANAGER_UA}`,
      EKB_NO_BINDS: `Клієнта не знайдено, ${CONTACT_MANAGER_UA}`,
      EKB_SERVICE_BAD_DATA: ERROR_SOMETHING_UA,
      EKB_SERVICE_CLIENT_IS_PHYS:
        "За наявною інформацією, Клієнт не зареєстрований як Фізична особа-підприємець.",
      CP_ID_NOT_FOUND:
        "Нажаль, щось пішло не так або перевищено 48 годин з моменту формування заявки. Спробуйте оновитися та повторно сформувати КП по клієнтові.",
      RESTRICTIONS_AGE:
        "Нажаль послуга недоступна. Дата реєстрації Підприємства менше 12 місяців",
      RESTRICTIONS_STOPS:
        "Нажаль, послуга автоматичного розрахунку для Клієнта недоступна. Ви маєте змогу звернутися до закріпленого за Вами менеджера від Банку з метою додаткового аналізу можливості/неможливості оформлення.",
      MSB_JOURNAL_ERROR: ERROR_SOMETHING_UA,
      MSB_JOURNAL_DUPLICATE:
        "По клієнту вже є активна заявка в Банку, для можливості подачі нової заявки зверніться до Вашого закріпленого менеджера від Банку.",
      ECA_ERROR: ERROR_SOMETHING_UA,
      DB_ERROR: ERROR_SOMETHING_UA,
      INVALID_USER_BINDS: `Помилка облікового запису, ${CONTACT_MANAGER_UA}`,
      LEASING_OBJECT_ID_IS_NULL: "Обов'язковий параметр для заповнення",
      CONDITION_FLAG_IS_NULL: "Обов'язковий параметр для заповнення",
      FIELD_COST: "Обов'язковий параметр для заповнення",
      EMPTY_FIELD:
        "Незаповнені або некоректно заповнені обов'язкові параметри для завки, перевірте будь-ласка!",
      SELL_DATE:
        "Не вказанан дата або вона в минулому. Вкажіть орієнтовану дату оформлення/відвантаження товару.",
      CUSTOM_SCHEDULE_MONTH_COUNT:
        "Оберіть мін. 2 макс. 6 місяців погашення основного боргу.",
      CUSTOM_SCHEDULE_MORE_6_MONTH:
        "При адаптованому графіку, перший платіж повинен бути не пізніше 6-ти місяців від дати оформлення.",
      INTEREST_RATE_GETTING_ERROR: ERROR_SOMETHING_UA,
      PDF_CREATION_ERROR: ERROR_SOMETHING_UA,
      CALCULATOR_ERROR: ERROR_SOMETHING_UA,
      SERVICE_CRM_ERROR:
        "Заявка в кредитному журналі створена. Виникла помилка формування сервісної зустрічі зі збору документів.",
      UPLOAD_FILE_ERROR: "Помилка збереження файлу.",
      SHP_API_ERROR: ERROR_SOMETHING_UA,
      PERSONAL_SERVICE_ERROR:
        "Помилка пошуку закріпленого від Банку менеджера.",
      SERVICE_LIMIT_ERROR: ERROR_SOMETHING_UA,
      SERVICE_STOP_ERROR: ERROR_SOMETHING_UA,
      HTTP_API_ERROR: ERROR_SOMETHING_UA,
      SEND_MAIL_ERROR:
        "Нажаль, щось пішло не так. Спробуйте ще раз пізніше або завантажте пропозицію та самостійно відправте на пошту клієнтові.",
      FILE_EMPTY_ERROR: ERROR_SOMETHING_UA,
      FILE_SIZE_ERROR: "Перевищено допустимий об'єм в 5МВ.",
      FILE_EXT_ERROR: "Невірний формат файлу.",
      WRONG_AUTH_USER: "Невірний логін або пароль",
      CONFIRM_API_ERROR:
        "На жаль, сталась помилка при відправленні повідомлення",
      WRONG_PHONE_NUMBER_FORMAT: "Невірний формат телефону (+380ХХYYYYYYY)",
      NOT_EXISTS_USER: "Користувача з номером {phone} не знайдено.",
      BLOCK_AUTH_USER:
        "Користувач заблокований! Зверніться до свого керівника/закріпленого менеджера від банку",
      LOCK_AUTH_USER: "Користувач заблокований, зверніться до свого керівника.",
      NON_ACTUAL_USER:
        "Користувача не актуалізовано, зверніться до свого керівника.",

      TOTAL_CHECKOUT_DATA_ERROR: INCORRECT_DATA_UA,
      PHONE_ALREADY_EXISTS: "Вже є користувач з даним номером телефону.",
      ADD_EMPLOYEE_VALIDATE_ERROR: INCORRECT_DATA_UA,
      EDIT_EMPLOYEE_VALIDATE_ERROR: INCORRECT_DATA_UA,
      EDIT_EMPLOYEE_ALL_FIELDS_IDENTICAL:
        "Невдале редагування, всі поля ідентичні.",
      EDIT_EMPLOYEE_INVALID_INN: "Невалідний ІПН",
      NOT_EXIST_STORE: "Неіснуюча організація в БД.",
      STORE_HO_VALIDATION: INCORRECT_DATA_UA,
      STORE_BAD_MANAGER_LDAP: "Порожній або некоректний ldap менеджера",
      STORE_MANAGER_LDAP_NOT_FOUND: "Такого менеджера не існує",
      STORE_ALL_FIELDS_IDENTICAL: "Невдале редагування, всі поля ідентичні.",
      NOT_EXIST_ORG: "Несуществующая организация в БД",
      ALREADY_EXIST_ORG: "Организация с таким ЕГРПОУ Уже существует",
      ORG_HO_VALIDATION: INCORRECT_DATA_UA,
      NOT_VALID_FIELD: INCORRECT_DATA_UA,

      ORG_SUPPORT_LDAP: "Не знайдено співробітника підтримки.",
      ORG_EMPTY_OBJ_TYPES:
        "Некоректно вказана категорія (порожня або неправильне значення).",
      INVALID_FORM_FIELD:
        "Некоректне заповненння даних (порожні поля або неправильні значення).",
      EMPTY_PROGRAM:
        "Некоректно заповнена програма (порожня або неправильне значення).",
      NO_ATTEMPTS_OTP: "Вичерпані спроби введення ОТП паролю.",
      CHECK_INSIDERS_LITE_ERROR: "Помилка API сервісу Інсайдерів",
      ORG_LOCKED:
        "По організації виявлено обмеження в доступі, зверніться за консультацією до свого менеджера від банку.",
      ORG_BAD_NAME: "Організація не знайдена/некоректні дані",
      ORG_BAD_SMS_NAME: "Некоректна назва організації латиницею (для СМС)",
      ORG_EMPTY_ADDR: "Не вказано ID адреси.",
      ADDR_API_ERROR: "Виникла помилка сервісу адресів, спробуйте пізніше.",
      ADDR_RESTRICTION:
        "З цією адресою працювати неможливо - вона відноситься до групи заборонених!",
      NOT_ESA_ADMIN_ACCESS: "Доступ заборонено.",
      UNKNOWN_ERROR: ERROR_SOMETHING_UA,

      addEmployee: "Помилка при додаванні співробітника!",
      updateEmployee: "Помилка при редагуванні співробітника!",
      activateEmployee: "Помилка при активації співробітника!",
      lockEmployee: "Помилка при блокуванні співробітника!",
      blockEmployee: "Помилка при блокуванні співробітника!",
      unlockEmployee: "Помилка при розблокуванні співробітника!",
      unblockEmployee: "Помилка при розблокуванні співробітника!",
      addOrg: "Помилка при додаванні компанії!",
      updateOrg: "Помилка при редагуванні компанії!",
      addStore: "Помилка при додаванні салону!",
      updateStore: "Помилка при редагуванні салону!",
      addCompany: "Помилка при додаванні компанії!",
      updateCompany: "Помилка при редагуванні компанії!",
      calcPledgeDetails: ERROR_SOMETHING_UA,
      isActualEndsSoon:
        "Увага! У вас закінчується термін активації. Зверніться до свого керівника інакше обліковий запис буде заблоковано!",

      FILE_REPORT_ERROR: "Під час вивантаження звіту в файл виникла помилка",
      DEALER_INFO_NOT_FOUND_BY_OKPO:
        "Інформація про дилера по ЄДРПОУ не знайдено",
      SELLER_OKPO_FORMAT:
        "ЄДРПОУ продавця передано у некоректному вигляді (тільки цифри, довжина 8 - 10)",
      SELLER_SEARCH_ERROR:
        "Трапилася помилка під час пошуку даних по ЄДРПОУ продавця",
      SELLER_ORG_LOCKED: "Організація продавця заблокована",
      SELLER_INFO_CP_ERROR:
        "У попередньому КП була знайдена інформація про продавця",
      SELLER_ADD_ILLEGAL_CP_STATE_ERROR:
        "Статус КП не дозволяє вказати ОКПО продавця",
      WRONG_OPERATION: ERROR_SYSTEM_UA,
      NOT_ESA_USER_ACCESS: "Немає доступу до інтерфейсу у ЄСА користувача",
      MSB_JOURNAL_EDIT_ERROR:
        "Заявка в кредитному журналі створена та сформована зустріч. Виникла помилка оновлення даних по зустрічі в журналі.",
      EKB_LINK_ERROR: "Помилка отримання пов'язаних осіб з підприємством",
      SERVICE_TASK_DOC_ERROR:
        "Заявка в кредитному журналі створена. Виникла помилка формування завдання на збір документів.",
      WEB_REQUEST_DATA_INVALID:
        "Ви обрали час зустрічі у минулому. Оберіть будь ласка новий час зустрічі.",
      REQUEST_DATA_INVALID:
        "Некоректний формат переданих даних у параметрах або запиті!",
      WRONG_CHOOSE_DATE:
        "Періоду вказано неправильно, оберіть початкову та кінцеву дату",
      rangeOneDay: "1 день",
      rangeOneMonth: "1 місяць",
      EMPTY_PROGRAMS_ESA:
        "Відсутня інформація що до активних програм фінансування у данного дилера.",
      EMPTY_PROGRAMS:
        "Відсутня інформація що до активних програм фінансування. Зверніться до Вашого менеджера від Банку.",
      CHECK_KVED_ERROR:
        "Відсутня інформація про КВЕД клієнта. Зверніться до Вашого менеджера від Банку.",
      CHECK_AGRO_ERROR:
        "Відсутня інформація про ознаку АГРО клієнта. Зверніться до Вашого менеджера від Банку.",
      getRateStepThree: ERROR_SOMETHING_UA
    },
    app: {
      attempt: "спроба",
      attempts: "спроби"
    },
    $vuetify: {
      badge: "Знак",
      close: "Закрити",
      dataIterator: {
        noResultsText: "В результаті пошуку нічого не знайдено",
        loadingText: "Завантаження..."
      },
      dataTable: {
        itemsPerPageText: "Рядків на сторінці:",
        ariaLabel: {
          sortDescending: "Відсортовано за спаданням.",
          sortAscending: "Відсортовано за зростанням.",
          sortNone: "Не відсортовано.",
          activateNone: "Активувати, щоб видалити сортування.",
          activateDescending: "Активувати, щоб відсортувати за спаданням.",
          activateAscending: "Активувати, щоб відсортувати за зростанням."
        },
        sortBy: "Відсортувати за"
      },
      dataFooter: {
        itemsPerPageText: "Елементів на сторінці:",
        itemsPerPageAll: "Всі",
        nextPage: "Наступна сторінка",
        prevPage: "Попередня сторінка",
        firstPage: "Перша сторінка",
        lastPage: "Остання сторінка",
        pageText: "{0}-{1} з {2}"
      },
      datePicker: {
        itemsSelected: "{0} вибрано",
        nextMonthAriaLabel: "Наступного місяця",
        nextYearAriaLabel: "Наступного року",
        prevMonthAriaLabel: "Попередній місяць",
        prevYearAriaLabel: "Попередній рік"
      },
      noDataText: "Немає даних для відображення",
      carousel: {
        prev: "Попередній слайд",
        next: "Наступий слайд",
        ariaLabel: {
          delimiter: "Слайд {0} з {1}"
        }
      },
      calendar: {
        moreEvents: "Ще {0}"
      },
      fileInput: {
        counter: "{0} файлів",
        counterSize: "{0} файлів ({1} загалом)"
      },
      timePicker: {
        am: "AM",
        pm: "PM"
      },
      pagination: {
        ariaLabel: {
          wrapper: "Навігація по сторінках",
          next: "Наступна сторінка",
          previous: "Попередня сторінка",
          page: "Перейти на сторінку {0}",
          currentPage: "Поточна сторінка, Сторінка {0}"
        }
      }
    }
  }
};

import * as authService from "@/services/auth.service";
import * as userServices from "@/services/user.services";
import * as offerService from "@/services/offer.service";
import * as ordersService from "@/services/orders.service";
import * as settingsServices from "@/services/settings.services";
import errorHandler from "@/utils/errorHandler";
import createMockData from "@/mock/mockNewData";

export default {
  actions: {
    async sendPhone({ commit }, { phone }) {
      const fomData = new FormData();

      fomData.append("phone", phone);
      return await authService
        .sendPhone(fomData)
        .then(() => {
          commit("auth_status", [false, "SEND_PHONE"]);
        })
        .catch(err => {
          let code = errorHandler(err, "ERROR_AUTH_SITE");

          commit("auth_error", code);
          console.log("actions catch sendPhone");
        });
    },
    async sendOtp({ commit }, { otp }) {
      let token = authService.createToken();

      return await authService
        .sendOtp(otp)
        .then(res => {
          let data = res.data;

          if (data && data.state === "OK") {
            localStorage.setItem("token", `${token}`);
            commit("auth_status", [token, "SUCCESS_AUTH_SITE"]);
          } else {
            commit("auth_status", [false, `${data.state}`]);
          }
        })
        .catch(err => {
          let code = errorHandler(err, "ERROR_AUTH_SITE");

          commit("auth_error", code);
          console.log("actions catch sendOtp");
        });
    },
    async logout({ commit }) {
      if (this.state.status === "create") {
        return false;
      }
      await authService
        .logout()
        .then(() => {
          commit("logout");
        })
        .catch(err => {
          let code = errorHandler(err, "ERROR_LOGOUT");

          commit("logout");
          console.log("actions catch logout", code);
        });
    },
    async esaLogout({ commit }) {
      if (this.state.status === "create") {
        return false;
      }
      await authService
        .esaLogout()
        .then(() => {
          commit("logout");
        })
        .catch(err => {
          let code = errorHandler(err, "ERROR_ESA_LOGOUT");

          commit("logout");
          console.log("actions catch esaLogout", code);
        });
    },
    async getUserInfo({ commit }) {
      await userServices
        .getUserInfo()
        .then(res => {
          let user = JSON.stringify(res.data);

          localStorage.setItem("info", user);
          commit("userInfo_success", res.data);
        })
        .catch(err => {
          let code = errorHandler(err, "ERROR_USER");

          localStorage.removeItem("info");
          commit("userInfo_error", code);
          console.log("actions catch getUserInfo", code);
        });
    },
    async getOfferDefaultOptions({ commit }, { cpId, authEsa }) {
      await offerService
        .getOfferDefaultOptions(cpId, authEsa)
        .then(res => {
          commit("offerDefaultOptions_success", res.data);
        })
        .catch(err => {
          let code = errorHandler(err, "getDefaultOptions");

          commit("offerDefaultOptions_error", code);
          console.log("actions catch getOfferDefaultOptions", code);
        });
    },
    async getProgramsFinancing({ commit }) {
      await ordersService
        .getPrograms()
        .then(res => {
          commit("programs_success", res.data);
        })
        .catch(err => {
          let code = errorHandler(err, "GET_DATA");

          commit("programs_error", code);
          console.log("actions catch getPrograms", code);
        });
    },
    async getOrders({ commit }) {
      await ordersService
        .getOrders()
        .then(res => {
          commit("orders_success", res.data);
        })
        .catch(err => {
          let code = errorHandler(err, "GET_DATA");

          commit("orders_error", code);
          console.log("actions catch getOrders", code);
        });
    },
    setOfferStatus({ commit }, status) {
      commit("offer_status", status);
    },
    showDialog({ commit }, mode) {
      commit("show_dialog", mode);
    },
    async getDefaultSettings({ commit }) {
      await settingsServices
        .getDefaultSettings()
        .then(res => {
          commit("defaultSettings_success", res.data);
        })
        .catch(err => {
          let code = errorHandler(err, "getDefaultOptions");

          commit("defaultSettings_error", code);
          console.log("actions catch getDefaultSettings", code);
        });
    },
    async getEmployees({ commit }, self) {
      await settingsServices
        .getEmployees()
        .then(res => {
          let data = settingsServices.createDataForTableStaff(res.data, self);

          commit("staff_success", data);
        })
        .catch(err => {
          let code = errorHandler(err, "GET_DATA");

          commit("staff_error", code);
          console.log("actions catch getStaff", code);
        });
    },
    async getEsaUserInfo({ commit }) {
      if (this.state.devEnv) {
        let token = authService.createToken();
        const data = createMockData("userInfoEsa");

        localStorage.setItem("info", JSON.stringify(data));
        localStorage.setItem("token", `${token}`);
        commit("auth_status", [token, "SUCCESS_AUTH_ESA"]);
        commit("ecaUserInfo_success", data);
        // mock
        return;
      }

      await userServices
        .getEsaUserInfo()
        .then(res => {
          let user = JSON.stringify(res.data);
          let token = authService.createToken();

          localStorage.setItem("info", user);
          localStorage.setItem("token", `${token}`);
          commit("auth_status", [token, "SUCCESS_AUTH_ESA"]);
          commit("ecaUserInfo_success", res.data);
        })
        .catch(err => {
          let code = errorHandler(err, "ERROR_USER");

          commit("auth_error", "ERROR_AUTH_ESA");
          commit("ecaUserInfo_error", code);
        });
    },
    async getEsaOrdersDefaultOptions({ commit }) {
      if (this.state.devEnv) {
        commit("ordersDefaultOptions_success", createMockData("defaultsEsa"));
        // mock
        return;
      }
      await ordersService
        .getEsaOrdersDefaultOptions()
        .then(res => {
          commit("ordersDefaultOptions_success", res.data);
        })
        .catch(err => {
          let code = errorHandler(err, "getDefaultOptions");

          commit("ordersDefaultOptions_error", code);
          console.log("actions catch getOrdersDefaultOptions", code);
        });
    },
    async getEsaOrders({ commit }, { data }) {
      if (this.state.devEnv) {
        commit("orders_success", createMockData("findCpEsa"));
        // mock
        return;
      }

      await ordersService
        .getEsaOrders(data)
        .then(res => {
          commit("orders_success", res.data);
        })
        .catch(err => {
          let code = errorHandler(err, "GET_DATA");

          commit("orders_error", code);
          console.log("actions catch getEsaOrders", code);
        });
    },
    async getCompanies({ commit }) {
      if (this.state.devEnv) {
        commit("companies_success", createMockData("getAllOrg"));
        // mock
        return;
      }

      await settingsServices
        .getCompanies()
        .then(res => {
          commit("companies_success", res.data);
        })
        .catch(err => {
          let code = errorHandler(err, "GET_DATA");

          commit("companies_error", code);
          console.log("actions catch getCompanies", code);
        });
    },
    async getStaffCompany({ commit }, { orgId, self }) {
      await settingsServices
        .getStaffCompany(orgId)
        .then(res => {
          let data = settingsServices.createDataForTableStaff(res.data, self);

          commit("staff_success", data);
        })
        .catch(err => {
          let code = errorHandler(err, "GET_DATA");

          commit("staff_error", code);
          console.log("actions catch getStaffCompany", code);
        });
    },
    async getStoresCompany({ commit }, { orgId }) {
      await settingsServices
        .getStoresCompany(orgId)
        .then(res => {
          commit("stores_success", res.data);
        })
        .catch(err => {
          let code = errorHandler(err, "GET_DATA");

          commit("stores_error", code);
          console.log("actions catch getStoresCompany", code);
        });
    }
  }
};

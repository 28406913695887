const userInfoEsa = {
  esaSid: "240405pbs182pvzn11p0",
  fio: "****** ******* ******",
  ldap: "DN050981ZVM",
  isManageAvailable: true,
  avalCpTypeFilterValues: ["PB", "DEALER"],
  isCpTypeFilterView: true,
  userGrants: ["SUPPORT_PB", "ADMINISTRATOR"],
  user: {
    agrSignDt: "2024-04-05T10:50:28.195152179",
    fio: "****** ******* ******"
  }
};

// esa/defaults
const defaultsEsa = {
  employeeBinds: [
    {
      value: "SUB_DIRECTOR"
    },
    {
      value: "DIRECTOR"
    },
    {
      value: "HEAD"
    },
    {
      value: "MANAGER"
    },
    {
      value: "SUB_HEAD"
    }
  ],
  allEsaGrantType: ["ADMINISTRATOR", "USER", "SUPPORT_PB", "SUPPORT_DL"],
  cpStateForFindCp: [
    "CP_SAVED",
    "JOURNAL_DUPLICATE",
    "JOURNAL_ACCEPTED",
    "JOURNAL_ERROR"
  ],
  allObjectDetails: [
    {
      id: 6,
      nameDesc: "Обладнання",
      newPrepayment: 30,
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "NO",
      flRegSh: "NO",
      subObjs: [],
      value: "equipment"
    },
    {
      id: 7,
      nameDesc: "Спецтехніка JCB",
      newPrepayment: 20,
      secondHandPrepayment: 30,
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "OPT_NO",
      flRegSh: "OPT_NO",
      sumRegNew: 4000,
      sumRegSh: 4000,
      subObjs: [],
      value: "st_jcb"
    },
    {
      id: 2,
      nameDesc: "Комерційний транспорт",
      newPrepayment: 20,
      secondHandPrepayment: 30,
      flPfNew: "OPT_NO",
      flPfSh: "NO",
      flRegNew: "YES",
      flRegSh: "YES",
      sumRegNew: 1500,
      sumRegSh: 1500,
      subObjs: [
        {
          id: 3,
          nameDesc: "Причепи та напівпричепи"
        },
        {
          id: 4,
          nameDesc: "Автобуси, Мікроавтобуси"
        },
        {
          id: 1,
          nameDesc: "Вантажні ТЗ вантажепід`ємністю від 2500 кг"
        },
        {
          id: 2,
          nameDesc:
            "Фургони та вантажні ТЗ вантажепід`ємністю від 1000 кг до 2500 кг"
        }
      ],
      value: "commercial_auto"
    },
    {
      id: 9,
      nameDesc: "Легкові авто (електромобілі)",
      newPrepayment: 20,
      secondHandPrepayment: 30,
      flPfNew: "YES",
      flPfSh: "NO",
      flRegNew: "YES",
      flRegSh: "YES",
      sumRegNew: 1500,
      sumRegSh: 1500,
      subObjs: [],
      value: "private_auto_el"
    },
    {
      id: 8,
      nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
      newPrepayment: 20,
      secondHandPrepayment: 30,
      flPfNew: "OPT_NO",
      flPfSh: "NO",
      flRegNew: "YES",
      flRegSh: "YES",
      sumRegNew: 1500,
      sumRegSh: 1500,
      subObjs: [],
      value: "commercial_auto_less_1"
    },
    {
      id: 1,
      nameDesc: "Легкові авто",
      newPrepayment: 20,
      secondHandPrepayment: 30,
      flPfNew: "YES",
      flPfSh: "NO",
      flRegNew: "YES",
      flRegSh: "YES",
      sumRegNew: 1500,
      sumRegSh: 1500,
      subObjs: [],
      value: "private_auto"
    },
    {
      id: 4,
      nameDesc: "Навісна сільгосптехніка",
      newPrepayment: 30,
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "NO",
      flRegSh: "NO",
      subObjs: [],
      value: "attached_agricultural_machinery"
    },
    {
      id: 5,
      nameDesc: "Самохідна сільгосптехніка",
      newPrepayment: 20,
      secondHandPrepayment: 30,
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "YES",
      flRegSh: "YES",
      sumRegNew: 4000,
      sumRegSh: 4000,
      subObjs: [],
      value: "self_propelled_agricultural_machinery"
    },
    {
      id: 3,
      nameDesc: "Спецтехніка",
      newPrepayment: 20,
      secondHandPrepayment: 30,
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "OPT_NO",
      flRegSh: "OPT_NO",
      sumRegNew: 4000,
      sumRegSh: 4000,
      subObjs: [],
      value: "special_equipment"
    }
  ],
  ogrDataFilterCps: [
    {
      orgId: 18,
      orgName: "ФОП Тестовий",
      okpo: "2222222222",
      storeFilterSet: [],
      programs: [
        {
          value: "FL"
        }
      ]
    },
    {
      orgId: 10,
      orgName: "ФОП Тестовий-Федотович Иван Федотович...",
      okpo: "8435638594",
      storeFilterSet: [
        {
          storeId: 9,
          storeName: "Крутые Тачки",
          address:
            "Дніпропетровська обл., Васильківський р-н., с. Артемівка, вул. Червона, 5",
          addressId: "29NEYKRC47MN00"
        }
      ],
      programs: [
        {
          value: "CR"
        },
        {
          value: "FL"
        }
      ]
    },
    {
      orgId: 11,
      orgName: "ООО 'TEST TEST MCB'",
      okpo: "31435622",
      storeFilterSet: [
        {
          storeId: 11,
          storeName: "DDDD",
          address:
            "Вінницька обл., Хмільницький р-н., м. Аркадіївці, вул. Жукова, 1",
          addressId: "L3UQJKVJC6GZAA"
        }
      ],
      programs: [
        {
          value: "FL"
        }
      ]
    },
    {
      orgId: 12,
      orgName: "ТОВ АМАКО УКРАЇНА",
      okpo: "21665011",
      storeFilterSet: [
        {
          storeId: 10,
          storeName: "edit 2",
          address: "Вінницька обл., м. Вінниця, вул. 1-А Макаренка, 1",
          addressId: "3Z27O3TPOPIS00"
        },
        {
          storeId: 12,
          storeName: "pyfxtybt",
          address:
            "Волинська обл., Володимир-Волинський р-н., сел. Березовичі, вул. Адамівка, 8",
          addressId: "46AMOH5CD7OS0C"
        }
      ],
      programs: [
        {
          value: "FL"
        }
      ]
    },
    {
      orgId: 8,
      orgName: "ФОП Шавров Максим ",
      okpo: "3044921459",
      storeFilterSet: [],
      programs: [
        {
          value: "FL"
        }
      ]
    },
    {
      orgId: 16,
      orgName: "ТОВ ПРОГРАМIСТИ ТА KO МСБ-ТЕСТ)))))",
      okpo: "31451288",
      storeFilterSet: [
        {
          storeId: 15,
          storeName: "ПБДПЮА",
          address:
            "Дніпропетровська обл., м. Дніпро, вул. Набережна Перемоги, 30",
          addressId: "36I31MZKYIOD0A"
        },
        {
          storeId: 16,
          storeName: "второй",
          address:
            "Дніпропетровська обл., Верхньодніпровський р-н., с. Авксенівка, вул. Талова, 4",
          addressId: "27G2XFJA7XJC00"
        }
      ],
      programs: [
        {
          value: "FL"
        }
      ]
    },
    {
      orgId: 7,
      orgName: "ИП СТРЕЛЬНИКОВ",
      okpo: "1111111111",
      storeFilterSet: [],
      programs: [
        {
          value: "FL"
        }
      ]
    },
    {
      orgId: 9,
      orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
      okpo: "85624199",
      storeFilterSet: [
        {
          storeId: 7,
          storeName: "edit 2",
          address:
            "Дніпропетровська обл., Верхньодніпровський р-н., с. Вільні Хутори, вул. Крікента, 83",
          addressId: "8321SE9I3B52LF"
        },
        {
          storeId: 8,
          storeName: "ВАЗ1",
          address:
            "Івано-Франківська обл., Богородчанський р-н., смт Богородчани, вул. Бічна, 6",
          addressId: "36T2BC8Q4GAA7C"
        }
      ],
      programs: [
        {
          value: "FL"
        }
      ]
    },
    {
      orgId: 17,
      orgName: "dealer24",
      okpo: "14360570",
      storeFilterSet: [],
      programs: [
        {
          value: "FL"
        },
        {
          value: "FL_579"
        }
      ]
    }
  ],
  allPrograms: [
    {
      value: "FL"
    },
    {
      value: "FL_579"
    },
    {
      value: "CR"
    },
    {
      value: "CR_579"
    }
  ]
};

// esa/findCp
const findCpEsa = [
  {
    cpId: "E38072401T1696939878",
    crDt: "2023-10-10T15:19:11.789303",
    jurOkpo: "38072401",
    jurName: "ФГ УНІВЕРСАЛФАРМ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 20.67,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E38072401T1695907578",
    crDt: "2023-09-28T16:35:05.243543",
    jurOkpo: "38072401",
    jurName: "ФГ УНІВЕРСАЛФАРМ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 5.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E39104926T1694689840",
    crDt: "2023-09-14T14:14:31.878427",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_ACCEPTED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    journalRef: "230914ZAGUYE7LU4",
    cpAuthor: "DN080982PSV",
    msbJournalHyperLink: "https://10.56.0.19/occ3/#ref/230914ZAGUYE7LU4/total",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E30800198T1685967727",
    crDt: "2023-06-05T15:25:04.833682",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "CR_579"
    }
  },
  {
    cpId: "E30800198T1685966234",
    crDt: "2023-06-05T15:16:55.96879",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 21.77,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1685965165",
    crDt: "2023-06-05T14:49:44.652413",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E30800198T1681725961",
    crDt: "2023-04-17T13:16:57.866339",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 24.04,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "CR"
    }
  },
  {
    cpId: "E30800198T1679907670",
    crDt: "2023-03-27T12:02:04.612368",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 800000.0,
    discount: 0.0,
    prepaymentPercent: 30.0,
    periodType: {
      value: "M48"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "ssssssssssss",
        model: "sssss",
        cost: 800000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ ПРОГРАМIСТИ ТА KO МСБ-ТЕСТ)))))",
    orgOkpo: "31451288",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "CR_579"
    }
  },
  {
    cpId: "E30800198T1677761399",
    crDt: "2023-03-02T14:56:24.775407",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 1.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1666090470",
    crDt: "2022-10-18T13:54:52.489482",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E39104926T1665593973",
    crDt: "2022-10-12T20:00:54.62553",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2000000.0,
    discount: 0.0,
    prepaymentPercent: 40.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "ANNUITY"
    },
    scheduleDetail: [],
    stake: 12.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 4,
              nameDesc: "Автобуси, Мікроавтобуси"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 2000000.0,
        discount: 0
      }
    ],
    orgName: "ФОП Шавров Максим ",
    orgOkpo: "3044921459",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN010792PNA1",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1664807976",
    crDt: "2022-10-03T17:39:48.949119",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E30800198T1664784594",
    crDt: "2022-10-03T11:10:18.813586",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "CR_579"
    }
  },
  {
    cpId: "E39104926T1664465131",
    crDt: "2022-09-29T18:26:11.155801",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 1000000.0,
    discount: 0.0,
    prepaymentPercent: 40.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 14.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 1000000.0,
        discount: 0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN151169RNI1",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "CR"
    }
  },
  {
    cpId: "E39104926T1664465011",
    crDt: "2022-09-29T18:24:01.148339",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 0.0,
    prepaymentPercent: 35.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 14.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 4,
              nameDesc: "Автобуси, Мікроавтобуси"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 2500000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN050981ZVM",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "CR"
    }
  },
  {
    cpId: "E39104926T1664464869",
    crDt: "2022-09-29T18:21:45.487332",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 25000000.0,
    discount: 0.0,
    prepaymentPercent: 25.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 12.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 25000000.0,
        discount: 0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN050981ZVM",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1664376144",
    crDt: "2022-09-28T17:43:48.644172",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E38072401T1663747292",
    crDt: "2022-09-21T11:02:08.377732",
    jurOkpo: "38072401",
    jurName: "ФГ УНІВЕРСАЛФАРМ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 34343434.0,
    discount: 33.0,
    prepaymentPercent: 50.0,
    periodType: {
      value: "M48"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 0.01,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "ssssss",
        model: "ss",
        cost: 34343434.0,
        discount: 33.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "1004010121",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1663596187",
    crDt: "2022-09-19T17:04:54.158275",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "JOURNAL_ACCEPTED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 0.01,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    journalRef: "220919P6XSTXHXN8",
    cpAuthor: "DN080982PSV",
    msbJournalHyperLink: "https://10.56.0.19/occ3/#ref/220919P6XSTXHXN8/total",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1663580948",
    crDt: "2022-09-19T13:23:31.828177",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E30800198T1662127574",
    crDt: "2022-09-02T17:07:55.263368",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "JOURNAL_ACCEPTED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 13.7,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    journalRef: "220902FH97R3EK87",
    cpAuthor: "DN080982PSV",
    msbJournalHyperLink: "https://10.56.0.19/occ3/#ref/220902FH97R3EK87/total",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "CR"
    }
  },
  {
    cpId: "E30800198T1662122643",
    crDt: "2022-09-02T15:48:31.377199",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 0.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "CR_579"
    }
  },
  {
    cpId: "E30800198T1662048245",
    crDt: "2022-09-01T19:07:00.12439",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 13.6,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "CR"
    }
  },
  {
    cpId: "E30800198T1662047783",
    crDt: "2022-09-01T18:57:59.420838",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "CR_579"
    }
  },
  {
    cpId: "E30800198T1662047587",
    crDt: "2022-09-01T18:54:33.214856",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "CR_579"
    }
  },
  {
    cpId: "E30800198T1662047551",
    crDt: "2022-09-01T18:53:56.560094",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E30800198T1662046942",
    crDt: "2022-09-01T18:46:21.358627",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "CR_579"
    }
  },
  {
    cpId: "E30800198T1662040534",
    crDt: "2022-09-01T16:57:22.944269",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 0.01,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1662039319",
    crDt: "2022-09-01T16:37:01.966001",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E39104926T1648746961",
    crDt: "2022-03-31T20:47:40.287977",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_ERROR",
    managerId: 0,
    storeId: 0,
    cost: 50000.0,
    discount: 0.0,
    prepaymentPercent: 45.0,
    periodType: {
      value: "M48"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 4,
              nameDesc: "Автобуси, Мікроавтобуси"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 50000.0,
        discount: 0
      }
    ],
    orgName: "ФОП Шавров Максим ",
    orgOkpo: "3044921459",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN010792PNA1",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E39104926T1648725434",
    crDt: "2022-03-31T14:18:19.870441",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_ERROR",
    managerId: 0,
    storeId: 0,
    cost: 500000.0,
    discount: 0.0,
    prepaymentPercent: 45.0,
    periodType: {
      value: "M48"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 1,
              nameDesc: "Вантажні ТЗ вантажепід`ємністю від 2500 кг"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 500000.0,
        discount: 0
      }
    ],
    orgName: "ФОП Шавров Максим ",
    orgOkpo: "3044921459",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN010792PNA1",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E30800198T1647855169",
    crDt: "2022-03-21T11:33:11.383569",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 2,
              nameDesc:
                "Фургони та вантажні ТЗ вантажепід`ємністю від 1000 кг до 2500 кг"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E30800198T1647510735",
    crDt: "2022-03-17T11:52:37.44872",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 2,
              nameDesc:
                "Фургони та вантажні ТЗ вантажепід`ємністю від 1000 кг до 2500 кг"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E30800198T1646834113",
    crDt: "2022-03-09T16:55:31.241118",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 2,
              nameDesc:
                "Фургони та вантажні ТЗ вантажепід`ємністю від 1000 кг до 2500 кг"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E30800198T1646665331",
    crDt: "2022-03-07T17:02:45.609259",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E30800198T1646213478",
    crDt: "2022-03-02T11:31:27.631306",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "E39104926T1641289984",
    crDt: "2022-01-04T11:53:55.345799",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_ERROR",
    managerId: 0,
    storeId: 0,
    cost: 1700000.0,
    discount: 1.0,
    prepaymentPercent: 50.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 8.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 1700000.0,
        discount: 1.0
      }
    ],
    orgName: "***",
    orgOkpo: "14360570",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN050981ZVM",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E39104926T1637318827",
    crDt: "2021-11-19T12:48:12.567363",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 0.01,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E39104926T1637316568",
    crDt: "2021-11-19T12:11:42.772185",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 0.01,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1636965919",
    crDt: "2021-11-15T10:46:03.890394",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "JOURNAL_ACCEPTED",
    managerId: 0,
    storeId: 0,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 0.01,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    journalRef: "58F3253141F18FD76A861DBDAA04DB14",
    cpAuthor: "DN080982PSV",
    msbJournalHyperLink:
      "https://10.56.0.19/occ3/#ref/58F3253141F18FD76A861DBDAA04DB14/total",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1636624462",
    crDt: "2021-11-11T11:55:13.655084",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E3124216396T1635174628",
    crDt: "2021-10-25T18:11:22.282423",
    jurOkpo: "3124216396",
    jurName: "ФОП Кожура Елек Віталійович",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "23214299",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E3391909386T1633601496",
    crDt: "2021-10-07T13:13:03.270383",
    jurOkpo: "3391909386",
    jurName: "ФОП Терлецька Тетяна Юріівна",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 2000000.0,
    discount: 0.0,
    prepaymentPercent: 57.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 2000000.0,
        discount: 0
      }
    ],
    orgName: "ФОП Тестовий-Федотович Иван Федотович",
    orgOkpo: "8435638594",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN010792PNA1",
    personEkbId: "1004393126",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E3391909386T1633601215",
    crDt: "2021-10-07T13:09:30.918764",
    jurOkpo: "3391909386",
    jurName: "ФОП Терлецька Тетяна Юріівна",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 1111111.0,
    discount: 0.0,
    prepaymentPercent: 59.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 1111111.0,
        discount: 0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN140583SMP",
    personEkbId: "1004393126",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1633508962",
    crDt: "2021-10-06T11:29:54.984274",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "JOURNAL_ACCEPTED",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    journalRef: "E5F60B022197366883B81C61ED4097F4",
    cpAuthor: "DN080982PSV",
    msbJournalHyperLink:
      "https://10.56.0.19/occ3/#ref/E5F60B022197366883B81C61ED4097F4/total",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1633508837",
    crDt: "2021-10-06T11:27:53.012351",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "JOURNAL_ACCEPTED",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    journalRef: "5661F330B2F6F27D539EAA974CFE778E",
    cpAuthor: "DN080982PSV",
    msbJournalHyperLink:
      "https://10.56.0.19/occ3/#ref/5661F330B2F6F27D539EAA974CFE778E/total",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1633508587",
    crDt: "2021-10-06T11:24:07.585976",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "JOURNAL_ACCEPTED",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    journalRef: "3D38326025E9ED725A4BB0F28412DF41",
    cpAuthor: "DN080982PSV",
    msbJournalHyperLink:
      "https://10.56.0.19/occ3/#ref/3D38326025E9ED725A4BB0F28412DF41/total",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1632381300",
    crDt: "2021-09-23T10:14:58.197025",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "JOURNAL_ERROR",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1632295601",
    crDt: "2021-09-22T10:26:43.177648",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1632289963",
    crDt: "2021-09-22T08:52:44.594425",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1632132026",
    crDt: "2021-09-20T13:00:56.494789",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "JOURNAL_ERROR",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1631787348",
    crDt: "2021-09-16T13:16:09.330881",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "JOURNAL_ERROR",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.5,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1631604843",
    crDt: "2021-09-14T10:34:15.08832",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "JOURNAL_ERROR",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.5,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E30800198T1631532448",
    crDt: "2021-09-13T14:28:11.030916",
    jurOkpo: "30800198",
    jurName: "ТОВ СТОВ ІНГУЛОКАМ\u0027ЯНСЬКЕ",
    state: "JOURNAL_ERROR",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.5,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "5537173",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E96599547T1628277850",
    crDt: "2021-08-06T22:24:47.049081",
    jurOkpo: "96599547",
    jurName: "ТОВ TEST VIP КРЕДИТИ МСБ",
    state: "JOURNAL_DUPLICATE",
    managerId: 0,
    storeId: 0,
    cost: 20000.0,
    discount: 0.0,
    prepaymentPercent: 50.0,
    periodType: {
      value: "M36"
    },
    scheduleType: {
      value: "ANNUITY"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        cost: 20000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ ПРОГРАМIСТИ ТА KO МСБ-ТЕСТ)))))",
    orgOkpo: "31451288",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN010792PNA1",
    personEkbId: "22383228",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E85624185T1628245137",
    crDt: "2021-08-06T13:19:27.588599",
    jurOkpo: "85624185",
    jurName: "ТОВ TEST ФІНЛІЗИНГ МСБ (КАБІНЕТ ДИЛЕРА)",
    state: "JOURNAL_DUPLICATE",
    managerId: 0,
    storeId: 0,
    cost: 2000000.0,
    discount: 0.0,
    prepaymentPercent: 35.0,
    periodType: {
      value: "M36"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 2000000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ ПРОГРАМIСТИ ТА KO МСБ-ТЕСТ)))))",
    orgOkpo: "31451288",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN010792PNA1",
    personEkbId: "22383228",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E96599547T1628245038",
    crDt: "2021-08-06T13:18:06.703848",
    jurOkpo: "96599547",
    jurName: "ТОВ TEST VIP КРЕДИТИ МСБ",
    state: "JOURNAL_DUPLICATE",
    managerId: 0,
    storeId: 0,
    cost: 2000000.0,
    discount: 0.0,
    prepaymentPercent: 40.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "ANNUITY"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 2000000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ ПРОГРАМIСТИ ТА KO МСБ-ТЕСТ)))))",
    orgOkpo: "31451288",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN010792PNA1",
    personEkbId: "22383228",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E39104926T1628172352",
    crDt: "2021-08-05T17:06:25.712474",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_DUPLICATE",
    managerId: 0,
    storeId: 0,
    cost: 300000.0,
    discount: 0.0,
    prepaymentPercent: 50.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [],
          value: "commercial_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        cost: 300000.0,
        discount: 0
      }
    ],
    orgName: "ФОП Шавров Максим ",
    orgOkpo: "3044921459",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN010792PNA1",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E85624185T1628087964",
    crDt: "2021-08-04T17:40:07.211376",
    jurOkpo: "85624185",
    jurName: "ТОВ TEST ФІНЛІЗИНГ МСБ (КАБІНЕТ ДИЛЕРА)",
    state: "JOURNAL_DUPLICATE",
    managerId: 0,
    storeId: 0,
    cost: 200000.0,
    discount: 0.0,
    prepaymentPercent: 45.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 5,
          nameDesc: "Самохідна сільгосптехніка",
          subObjs: [],
          value: "self_propelled_agricultural_machinery"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        cost: 200000.0,
        discount: 0
      }
    ],
    orgName: "ФОП Тестовий-Федотович Иван Федотович",
    orgOkpo: "8435638594",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN010792PNA1",
    personEkbId: "22383228",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E85624185T1628086945",
    crDt: "2021-08-04T17:32:52.104235",
    jurOkpo: "85624185",
    jurName: "ТОВ TEST ФІНЛІЗИНГ МСБ (КАБІНЕТ ДИЛЕРА)",
    state: "JOURNAL_ACCEPTED",
    managerId: 0,
    storeId: 0,
    cost: 20000.0,
    discount: 0.0,
    prepaymentPercent: 30.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [],
          value: "commercial_auto"
        },
        conditionType: {
          value: "SECOND_HAND"
        },
        cost: 20000.0,
        discount: 0
      }
    ],
    orgName: "ФОП Шавров Максим ",
    orgOkpo: "3044921459",
    storeName: "",
    hasUaJournalState: false,
    journalRef: "469457471BFACF4EC05D81A7D2CDAE41",
    cpAuthor: "DN010792PNA1",
    msbJournalHyperLink:
      "https://10.56.0.19/occ3/#ref/469457471BFACF4EC05D81A7D2CDAE41/total",
    personEkbId: "22383228",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E39104926T1627626027",
    crDt: "2021-07-30T09:21:13.803694",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.5,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E39104926T1627622245",
    crDt: "2021-07-30T08:18:18.090872",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_DUPLICATE",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.5,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN080982PSV",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E39104926T1627568628",
    crDt: "2021-07-29T17:24:33.617162",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_ACCEPTED",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.5,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    journalRef: "9B6FD5B9A02055A0B5A23A3926705BE7",
    cpAuthor: "DN080982PSV",
    msbJournalHyperLink:
      "https://10.56.0.19/occ3/#ref/9B6FD5B9A02055A0B5A23A3926705BE7/total",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E39104926T1627474634",
    crDt: "2021-07-28T15:17:58.423428",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_ACCEPTED",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.5,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    journalRef: "8581E18E3C5726222314DD9E29B2B6ED",
    cpAuthor: "DN080982PSV",
    msbJournalHyperLink:
      "https://10.56.0.19/occ3/#ref/8581E18E3C5726222314DD9E29B2B6ED/total",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E39104926T1627452618",
    crDt: "2021-07-28T09:11:23.136997",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_ACCEPTED",
    managerId: 0,
    storeId: 0,
    cost: 250000.0,
    discount: 1.6,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 9.5,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 150000.0,
        discount: 2.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 100000.0,
        discount: 1.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    journalRef: "1EAAE3C7E61E20FAC4A405B5CF63CE77",
    cpAuthor: "DN080982PSV",
    msbJournalHyperLink:
      "https://10.56.0.19/occ3/#ref/1EAAE3C7E61E20FAC4A405B5CF63CE77/total",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "E33282104T1627445252",
    crDt: "2021-07-28T07:08:21.94159",
    jurOkpo: "33282104",
    jurName: "ТОВ ТОМ-ІНВЕСТБУД",
    state: "CP_SAVED",
    managerId: 0,
    storeId: 0,
    cost: 34534500.0,
    discount: 2.0,
    prepaymentPercent: 25.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 8,
          nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
          subObjs: [],
          value: "commercial_auto_less_1"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "sdf",
        model: "sdf",
        cost: 34534500.0,
        discount: 2.0
      }
    ],
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "DN050981ZVM",
    personEkbId: "1032368866",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  }
];

// /esa/cp/addCalcTask?okpo=37518714&program=FL_579
const addCalcTaskEsa = {
  content: {
    cpId: "E37518714T1698758744",
    task: {
      cpId: "E37518714T1698758744",
      okpo: "37518714",
      createDt: "2023-10-31T15:25:44.94024",
      cpType: "PB"
    },
    state: "INIT_CLIENT_DATA_START",
    fundProgram: {
      value: "FL_579"
    }
  },
  finalRespState: false
};

// esa/cp/currentCondition?cpId=E37518714T1698758744
const currentConditionESA = {
  content: {
    cpId: "E37518714T1698758744",
    task: {
      cpId: "E37518714T1698758744",
      okpo: "37518714",
      createDt: "2023-10-31T15:25:44.94024",
      finalDt: "2023-10-31T15:25:45.172348",
      cpType: "PB"
    },
    clientData: {
      okpo: "37518714",
      orgName: "ТОВ ТК ОСКАР",
      phoneDataList: [
        {
          id: 1,
          maskedPhone: "+3809954***05"
        }
      ],
      emailDataList: [
        {
          id: 1,
          maskedEmail: "KUR*******KD@UKR.NET"
        }
      ],
      flAgro: "N"
    },
    sellerData: {
      ldap: "DN050981ZVM",
      branch: "DNH0",
      flagHo: true
    },
    state: "INIT_CLIENT_DATA_FINISHED",
    fundProgram: {
      value: "FL_579"
    }
  },
  finalRespState: true
};

// esa/cp/defaults?cpId=E37518714T1698758744
const defaultsEsaCp = {
  // TODO : change after answer from backEnd
  schedules: [
    {
      value: "CLASSIC"
    },
    {
      value: "CUSTOM"
    }
  ],
  periodTypes: [
    {
      value: "M12"
    },
    {
      value: "M24"
    },
    {
      value: "M36"
    },
    {
      value: "M48"
    },
    {
      value: "M60"
    }
  ],
  prepaymentTypes: [
    {
      value: "P20"
    },
    {
      value: "P25"
    },
    {
      value: "P30"
    },
    {
      value: "P35"
    },
    {
      value: "P40"
    },
    {
      value: "P45"
    },
    {
      value: "P50"
    },
    {
      value: "P55"
    },
    {
      value: "P60"
    },
    {
      value: "P65"
    },
    {
      value: "P70"
    }
  ],
  conditionTypes: [
    {
      value: "NEW"
    },
    {
      value: "SECOND_HAND"
    }
  ],
  objectDetails: [
    {
      id: 5,
      nameDesc: "Самохідна сільгосптехніка",
      newPrepayment: 20.0,
      secondHandPrepayment: 30.0,
      matrixId: "1",
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "YES",
      flRegSh: "YES",
      sumRegNew: 4000.0,
      sumRegSh: 4000.0,
      subObjs: [],
      value: "self_propelled_agricultural_machinery"
    },
    {
      id: 4,
      nameDesc: "Навісна сільгосптехніка",
      newPrepayment: 30.0,
      matrixId: "1",
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "NO",
      flRegSh: "NO",
      subObjs: [],
      value: "attached_agricultural_machinery"
    },
    {
      id: 2,
      nameDesc: "Комерційний транспорт",
      newPrepayment: 20.0,
      secondHandPrepayment: 30.0,
      matrixId: "1",
      flPfNew: "OPT_NO",
      flPfSh: "NO",
      flRegNew: "YES",
      flRegSh: "YES",
      sumRegNew: 1500.0,
      sumRegSh: 1500.0,
      subObjs: [
        {
          id: 3,
          nameDesc: "Причепи та напівпричепи"
        },
        {
          id: 4,
          nameDesc: "Автобуси, Мікроавтобуси"
        },
        {
          id: 1,
          nameDesc: "Вантажні ТЗ вантажепід`ємністю від 2500 кг"
        },
        {
          id: 2,
          nameDesc:
            "Фургони та вантажні ТЗ вантажепід`ємністю від 1000 кг до 2500 кг"
        }
      ],
      value: "commercial_auto"
    },
    {
      id: 3,
      nameDesc: "Спецтехніка",
      newPrepayment: 20.0,
      secondHandPrepayment: 30.0,
      matrixId: "1",
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "OPT_NO",
      flRegSh: "OPT_NO",
      sumRegNew: 4000.0,
      sumRegSh: 4000.0,
      subObjs: [],
      value: "special_equipment"
    },
    {
      id: 8,
      nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
      newPrepayment: 20.0,
      secondHandPrepayment: 30.0,
      matrixId: "1",
      flPfNew: "OPT_NO",
      flPfSh: "NO",
      flRegNew: "YES",
      flRegSh: "YES",
      sumRegNew: 1500.0,
      sumRegSh: 1500.0,
      subObjs: [],
      value: "commercial_auto_less_1"
    }
  ],
  adaptDetails: [
    {
      value: "JAN"
    },
    {
      value: "FEB"
    },
    {
      value: "MAR"
    },
    {
      value: "APR"
    },
    {
      value: "MAY"
    },
    {
      value: "JUN"
    },
    {
      value: "JUL"
    },
    {
      value: "AUG"
    },
    {
      value: "SEP"
    },
    {
      value: "OCT"
    },
    {
      value: "NOV"
    },
    {
      value: "DEC"
    }
  ],
  defaultStake: 11.0,
  uirdTypes: [
    {
      code: 1,
      description: "виручка до 2 млн. Євро"
    },
    {
      code: 2,
      description: "виручка від 2 до 10 млн. Євро"
    },
    {
      code: 3,
      description: "виручка від 10 до 50 млн. Євро"
    },
    {
      code: 4,
      description: "виручка від 50 млн. Євро"
    }
  ],
  guarantee: false,
  editGuarantee: false,
  typeCompens: [
    {
      value: "SUM"
    },
    {
      value: "COST"
    }
  ],

  flIndRate: true,
  flIndPrComm: true
};

const getAllOrg = [
  {
    id: 7,
    name: "ИП СТРЕЛЬНИКОВ",
    okpo: "1111111111",
    employeeCount: 0,
    storeCount: 0,
    cpOnWeek: 0,
    cpCount: 0
  },
  {
    id: 12,
    name: "ТОВ АМАКО УКРАЇНА",
    okpo: "21665011",
    employeeCount: 1,
    storeCount: 2,
    cpOnWeek: 0,
    cpCount: 0
  },
  {
    id: 16,
    name: "ТОВ ПРОГРАМIСТИ ТА KO МСБ-ТЕСТ)))))",
    okpo: "31451288",
    employeeCount: 12,
    storeCount: 2,
    cpOnWeek: 0,
    cpCount: 0
  },
  {
    id: 8,
    name: "ФОП Шавров Максим ",
    okpo: "3044921459",
    employeeCount: 2,
    storeCount: 0,
    cpOnWeek: 0,
    cpCount: 0
  },
  {
    id: 11,
    name: "ООО \u0027TEST TEST MCB\u0027",
    okpo: "31435622",
    employeeCount: 1,
    storeCount: 1,
    cpOnWeek: 0,
    cpCount: 0
  },
  {
    id: 9,
    name: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    okpo: "85624199",
    employeeCount: 19,
    storeCount: 2,
    cpOnWeek: 0,
    cpCount: 76
  },
  {
    id: 18,
    name: "ФОП Тестовий",
    okpo: "2222222222",
    employeeCount: 0,
    storeCount: 0,
    cpOnWeek: 0,
    cpCount: 0
  },
  {
    id: 17,
    name: "dealer24",
    okpo: "14360570",
    employeeCount: 0,
    storeCount: 0,
    cpOnWeek: 0,
    cpCount: 0
  },
  {
    id: 10,
    name: "ФОП Тестовий-Федотович Иван Федотович...",
    okpo: "8435638594",
    employeeCount: 1,
    storeCount: 1,
    cpOnWeek: 0,
    cpCount: 0
  }
];

// esa/org/get?id=17
const org = {
  id: 17,
  name: "dealer24",
  okpo: "14360570",
  ekbId: "156503",
  otpRequired: false,
  translatedName: "dealer24",
  address: "",
  addressId: "14E1E8Y2IRPF70",
  programSettings: [
    {
      flIndRate: true,
      flIndPrComm: true,
      program: {
        value: "FL"
      },
      isActive: true,
      leasingObjects: [
        {
          id: 1,
          nameDesc: "Легкові авто",
          active: false,
          value: "private_auto"
        },
        {
          id: 2,
          nameDesc: "Комерційний транспорт",
          active: false,
          value: "commercial_auto"
        },
        {
          id: 3,
          nameDesc: "Спецтехніка",
          active: false,
          value: "special_equipment"
        },
        {
          id: 4,
          nameDesc: "Навісна сільгосптехніка",
          active: false,
          value: "attached_agricultural_machinery"
        },
        {
          id: 5,
          nameDesc: "Самохідна сільгосптехніка",
          active: false,
          value: "self_propelled_agricultural_machinery"
        },
        {
          id: 6,
          nameDesc: "Обладнання",
          active: false,
          value: "equipment"
        },
        {
          id: 7,
          nameDesc: "Спецтехніка JCB",
          active: false,
          value: "st_jcb"
        },
        {
          id: 8,
          nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
          active: false,
          value: "commercial_auto_less_1"
        },
        {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          active: false,
          value: "private_auto_el"
        }
      ]
    },
    {
      program: {
        value: "FL_579"
      },
      isActive: false,
      leasingObjects: [
        {
          id: 1,
          nameDesc: "Легкові авто",
          active: false,
          value: "private_auto"
        },
        {
          id: 2,
          nameDesc: "Комерційний транспорт",
          active: false,
          value: "commercial_auto"
        },
        {
          id: 3,
          nameDesc: "Спецтехніка",
          active: false,
          value: "special_equipment"
        },
        {
          id: 4,
          nameDesc: "Навісна сільгосптехніка",
          active: false,
          value: "attached_agricultural_machinery"
        },
        {
          id: 5,
          nameDesc: "Самохідна сільгосптехніка",
          active: false,
          value: "self_propelled_agricultural_machinery"
        },
        {
          id: 6,
          nameDesc: "Обладнання",
          active: false,
          value: "equipment"
        },
        {
          id: 7,
          nameDesc: "Спецтехніка JCB",
          active: false,
          value: "st_jcb"
        },
        {
          id: 8,
          nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
          active: false,
          value: "commercial_auto_less_1"
        },
        {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          active: false,
          value: "private_auto_el"
        }
      ]
    },
    {
      program: {
        value: "CR"
      },
      isActive: false,
      leasingObjects: [
        {
          id: 1,
          nameDesc: "Легкові авто",
          active: false,
          value: "private_auto"
        },
        {
          id: 2,
          nameDesc: "Комерційний транспорт",
          active: false,
          value: "commercial_auto"
        },
        {
          id: 3,
          nameDesc: "Спецтехніка",
          active: false,
          value: "special_equipment"
        },
        {
          id: 4,
          nameDesc: "Навісна сільгосптехніка",
          active: false,
          value: "attached_agricultural_machinery"
        },
        {
          id: 5,
          nameDesc: "Самохідна сільгосптехніка",
          active: false,
          value: "self_propelled_agricultural_machinery"
        },
        {
          id: 6,
          nameDesc: "Обладнання",
          active: false,
          value: "equipment"
        },
        {
          id: 7,
          nameDesc: "Спецтехніка JCB",
          active: false,
          value: "st_jcb"
        },
        {
          id: 8,
          nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
          active: false,
          value: "commercial_auto_less_1"
        },
        {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          active: false,
          value: "private_auto_el"
        }
      ]
    },
    {
      program: {
        value: "CR_579"
      },
      isActive: false,
      leasingObjects: [
        {
          id: 1,
          nameDesc: "Легкові авто",
          active: false,
          value: "private_auto"
        },
        {
          id: 2,
          nameDesc: "Комерційний транспорт",
          active: false,
          value: "commercial_auto"
        },
        {
          id: 3,
          nameDesc: "Спецтехніка",
          active: false,
          value: "special_equipment"
        },
        {
          id: 4,
          nameDesc: "Навісна сільгосптехніка",
          active: false,
          value: "attached_agricultural_machinery"
        },
        {
          id: 5,
          nameDesc: "Самохідна сільгосптехніка",
          active: false,
          value: "self_propelled_agricultural_machinery"
        },
        {
          id: 6,
          nameDesc: "Обладнання",
          active: false,
          value: "equipment"
        },
        {
          id: 7,
          nameDesc: "Спецтехніка JCB",
          active: false,
          value: "st_jcb"
        },
        {
          id: 8,
          nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
          active: false,
          value: "commercial_auto_less_1"
        },
        {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          active: false,
          value: "private_auto_el"
        }
      ]
    }
  ],
  managerLdap: "DG030588TVL",
  managerFio: "Томас Вячеслав Леонідович"
};

// esa/org/search?okpo=39104926
const newOrg = {
  ekbIdStr: "2R6VDLV55AA0",
  ekbIdNum: 1026188392,
  name: "ТОВ ЦЕНТР-ШИНА ГРУП",
  foundAddress: {
    color: "GREEN",
    addressId: "34AFCN7CTZ6P1C",
    addressUaText:
      "49033, УКРАЇНА, ОБЛ. ДНЕПРОПЕТРОВСКАЯ, Г. ДНЕПР, УЛ. КРАСНОПОЛЬСКАЯ, Б. 9",
    addrType: "Ukraine"
  },
  programSettings: [
    {
      program: {
        value: "FL"
      },
      isActive: true,
      leasingObjects: [
        {
          id: 1,
          nameDesc: "Легкові авто",
          active: false,
          value: "private_auto"
        },
        {
          id: 2,
          nameDesc: "Комерційний транспорт",
          active: false,
          value: "commercial_auto"
        },
        {
          id: 3,
          nameDesc: "Спецтехніка",
          active: false,
          value: "special_equipment"
        },
        {
          id: 4,
          nameDesc: "Навісна сільгосптехніка",
          active: false,
          value: "attached_agricultural_machinery"
        },
        {
          id: 5,
          nameDesc: "Самохідна сільгосптехніка",
          active: false,
          value: "self_propelled_agricultural_machinery"
        },
        {
          id: 6,
          nameDesc: "Обладнання",
          active: false,
          value: "equipment"
        },
        {
          id: 7,
          nameDesc: "Спецтехніка JCB",
          active: false,
          value: "st_jcb"
        },
        {
          id: 8,
          nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
          active: false,
          value: "commercial_auto_less_1"
        },
        {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          active: false,
          value: "private_auto_el"
        }
      ]
    },
    {
      program: {
        value: "FL_579"
      },
      isActive: false,
      leasingObjects: [
        {
          id: 1,
          nameDesc: "Легкові авто",
          active: false,
          value: "private_auto"
        },
        {
          id: 2,
          nameDesc: "Комерційний транспорт",
          active: false,
          value: "commercial_auto"
        },
        {
          id: 3,
          nameDesc: "Спецтехніка",
          active: false,
          value: "special_equipment"
        },
        {
          id: 4,
          nameDesc: "Навісна сільгосптехніка",
          active: false,
          value: "attached_agricultural_machinery"
        },
        {
          id: 5,
          nameDesc: "Самохідна сільгосптехніка",
          active: false,
          value: "self_propelled_agricultural_machinery"
        },
        {
          id: 6,
          nameDesc: "Обладнання",
          active: false,
          value: "equipment"
        },
        {
          id: 7,
          nameDesc: "Спецтехніка JCB",
          active: false,
          value: "st_jcb"
        },
        {
          id: 8,
          nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
          active: false,
          value: "commercial_auto_less_1"
        },
        {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          active: false,
          value: "private_auto_el"
        }
      ]
    },
    {
      program: {
        value: "CR"
      },
      isActive: false,
      leasingObjects: [
        {
          id: 1,
          nameDesc: "Легкові авто",
          active: false,
          value: "private_auto"
        },
        {
          id: 2,
          nameDesc: "Комерційний транспорт",
          active: false,
          value: "commercial_auto"
        },
        {
          id: 3,
          nameDesc: "Спецтехніка",
          active: false,
          value: "special_equipment"
        },
        {
          id: 4,
          nameDesc: "Навісна сільгосптехніка",
          active: false,
          value: "attached_agricultural_machinery"
        },
        {
          id: 5,
          nameDesc: "Самохідна сільгосптехніка",
          active: false,
          value: "self_propelled_agricultural_machinery"
        },
        {
          id: 6,
          nameDesc: "Обладнання",
          active: false,
          value: "equipment"
        },
        {
          id: 7,
          nameDesc: "Спецтехніка JCB",
          active: false,
          value: "st_jcb"
        },
        {
          id: 8,
          nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
          active: false,
          value: "commercial_auto_less_1"
        },
        {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          active: false,
          value: "private_auto_el"
        }
      ]
    },
    {
      program: {
        value: "CR_579"
      },
      isActive: false,
      leasingObjects: [
        {
          id: 1,
          nameDesc: "Легкові авто",
          active: false,
          value: "private_auto"
        },
        {
          id: 2,
          nameDesc: "Комерційний транспорт",
          active: false,
          value: "commercial_auto"
        },
        {
          id: 3,
          nameDesc: "Спецтехніка",
          active: false,
          value: "special_equipment"
        },
        {
          id: 4,
          nameDesc: "Навісна сільгосптехніка",
          active: false,
          value: "attached_agricultural_machinery"
        },
        {
          id: 5,
          nameDesc: "Самохідна сільгосптехніка",
          active: false,
          value: "self_propelled_agricultural_machinery"
        },
        {
          id: 6,
          nameDesc: "Обладнання",
          active: false,
          value: "equipment"
        },
        {
          id: 7,
          nameDesc: "Спецтехніка JCB",
          active: false,
          value: "st_jcb"
        },
        {
          id: 8,
          nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
          active: false,
          value: "commercial_auto_less_1"
        },
        {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          active: false,
          value: "private_auto_el"
        }
      ]
    }
  ]
};

// esa/searchManager?ldapOrFio=dn050981z
const manager = [
  {
    surname: "Зелик",
    firstName: "Володимир",
    patronymic: "Миронович",
    id: "DN050981ZVM"
  }
];

// --- //

// otp/check?otp=0000
const otp = { otpValue: "0000", state: "OK" };

const userInfo = {
  employeeId: 58,
  user: {
    id: 58,
    phoneNumber: "+380111111111",
    inn: "1111111111",
    fio: "Пупкин Фродо",
    createDt: "2021-05-13T10:48:01.855344",
    actualDt: "2021-08-11T10:48:01.855418",
    agrSignDt: "2021-05-13T10:48:40.090173"
  },
  role: {
    value: "HEAD"
  },
  availableStores: [
    {
      id: 8,
      orgId: 9,
      name: "ВАЗ1",
      managerLdap: "OD310888RVV",
      address:
        "Івано-Франківська обл., Богородчанський р-н., смт Богородчани, вул. Бічна, 6",
      addressId: "36T2BC8Q4GAA7C"
    },
    {
      id: 7,
      orgId: 9,
      name: "edit 2",
      managerLdap: "DN021285GAI1",
      address:
        "Дніпропетровська обл., Верхньодніпровський р-н., с. Вільні Хутори, вул. Крікента, 83",
      addressId: "8321SE9I3B52LF"
    }
  ],
  org: {
    id: 9,
    name: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    okpo: "85624199",
    otpNeed: false,
    translatedName: "TEST VIP ",
    address: "Київська обл., м. Бориспіль, пров. Соборний, 8, кв. 12",
    addressId: "324KMGITZHSNZ0",
    programs: []
  },
  manager: {
    fio: "Зелик Володимир Миронович",
    email: "bm.k@rvd.lVikuanitaiZreilapa",
    phone: "",
    support: false
  },
  isManageAvailable: true,
  isActualEndsSoon: true
};

const allPrograms = [];

const findCp = [
  {
    cpId: "39104926D9M58S8T1694691399669",
    crDt: "2023-09-14T14:38:01.178042",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_ERROR",
    managerId: 58,
    storeId: 8,
    cost: 2500000.0,
    discount: 10.0,
    prepaymentPercent: 26.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 3,
              nameDesc: "Причепи та напівпричепи"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 1500000.0,
        discount: 10.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 1000000.0,
        discount: 10.0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "39104926D9M58S8T1665594913621",
    crDt: "2022-10-12T20:15:58.845688",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 300000.0,
    discount: 0.0,
    prepaymentPercent: 45.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 3,
          nameDesc: "Спецтехніка",
          subObjs: [],
          value: "special_equipment"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 300000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "85624185D9M58S8T1664796888521",
    crDt: "2022-10-03T14:38:39.422062",
    jurOkpo: "85624185",
    jurName: "ТОВ TEST ФІНЛІЗИНГ МСБ (КАБІНЕТ ДИЛЕРА)",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 12010020.5,
    discount: 0.0,
    prepaymentPercent: 30.03,
    periodType: {
      value: "M60"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "екнг нго р апо апо апо рпоапо",
        model: "апро апо апо про про апро апр опр апр опр оао апоа",
        equipmentDetail:
          "апро апо апо про про апро апр опр апр опр оао апоаапро апо апо про про апро апр опр апр опр оао апоаапро апо апо про про апро апр опр апр опр оао апоаапро апо апо про про апро апр опр апр опр оао апоаапро апо апо про про апро апр опр апр опр оао апоаапро ",
        cost: 12000000.0,
        discount: 0
      },
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 4,
              nameDesc: "Автобуси, Мікроавтобуси"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "кпе рапр вар",
        model: "аврп ап па",
        equipmentDetail:
          "рапро апо апо про про апро апр опр апр опр оао апоаапро апо апо про про апро апр опр апр опр оао апоаапро апо апо про про апро апр опр апр опр оао апоаапро апо апо про про апро апр опр апр опр оао апоаапро апо апо про про апро апр опр апр опр оао апоаапро",
        cost: 10020.5,
        discount: 0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "1004010121",
    needCompleteMeet: false,
    program: {
      value: "CR_579"
    }
  },
  {
    cpId: "39104926D9M58S8T1663709891510",
    crDt: "2022-09-21T00:39:01.928789",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 2500000.0,
    discount: 0.0,
    prepaymentPercent: 25.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 12.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          subObjs: [],
          value: "private_auto_el"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 2500000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "CR"
    }
  },
  {
    cpId: "39104926D9M58S8T1663709436330",
    crDt: "2022-09-21T00:33:35.98955",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 4500000.0,
    discount: 0.0,
    prepaymentPercent: 25.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 14.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          subObjs: [],
          value: "private_auto_el"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 2000000.0,
        discount: 0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 2500000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "CR"
    }
  },
  {
    cpId: "39104926D9M58S8T1648682133761",
    crDt: "2022-03-31T02:16:01.254706",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 15000000.0,
    discount: 0.0,
    prepaymentPercent: 20.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [
            {
              id: 2,
              nameDesc:
                "Фургони та вантажні ТЗ вантажепід`ємністю від 1000 кг до 2500 кг"
            }
          ],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 15000000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL_579"
    }
  },
  {
    cpId: "39104926D9M58S8T1641373678175",
    crDt: "2022-01-05T11:08:25.963094",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 4500000.0,
    discount: 2.0,
    prepaymentPercent: 30.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.5,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 4,
          nameDesc: "Навісна сільгосптехніка",
          subObjs: [],
          value: "attached_agricultural_machinery"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 4500000.0,
        discount: 2.0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "39104926D9M58S8T1641373264799",
    crDt: "2022-01-05T11:01:39.793028",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 3500000.0,
    discount: 1.5,
    prepaymentPercent: 55.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 5.5,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 7,
          nameDesc: "Спецтехніка JCB",
          subObjs: [],
          value: "st_jcb"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 3500000.0,
        discount: 1.5
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "39104926D9M58S8T1641372970393",
    crDt: "2022-01-05T10:56:30.13888",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 2500000.0,
    discount: 1.0,
    prepaymentPercent: 50.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 0.1,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 2500000.0,
        discount: 1.0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "39104926D9M58S8T1641372843364",
    crDt: "2022-01-05T10:54:21.064323",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 1000000.0,
    discount: 0.0,
    prepaymentPercent: 40.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 2,
          nameDesc: "Комерційний транспорт",
          subObjs: [],
          value: "commercial_auto"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 1000000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "39104926D9M58S8T1641372608418",
    crDt: "2022-01-05T10:50:33.29234",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 17500000.0,
    discount: 1.5,
    prepaymentPercent: 50.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 0.01,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          subObjs: [],
          value: "private_auto_el"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 17500000.0,
        discount: 1.5
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "39104926D9M58S8T1641288017962",
    crDt: "2022-01-04T11:21:14.382343",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_ERROR",
    managerId: 58,
    storeId: 8,
    cost: 1500000.0,
    discount: 1.0,
    prepaymentPercent: 50.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 0.1,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 9,
          nameDesc: "Легкові авто (електромобілі)",
          subObjs: [],
          value: "private_auto_el"
        },
        conditionType: {
          value: "NEW"
        },
        cost: 1500000.0,
        discount: 1.0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "33282104D9M58S8T1626350188770",
    crDt: "2021-07-15T14:57:00.833186",
    jurOkpo: "33282104",
    jurName: "ТОВ ТОМ-ІНВЕСТБУД",
    state: "JOURNAL_DUPLICATE",
    managerId: 58,
    storeId: 8,
    cost: 2300000.0,
    discount: 0.1,
    prepaymentPercent: 25.0,
    periodType: {
      value: "M60"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "ап",
        model: "34",
        cost: 2300000.0,
        discount: 0.1
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "1032368866",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "33282104D9M58S8T1626348232181",
    crDt: "2021-07-15T14:24:11.603528",
    jurOkpo: "33282104",
    jurName: "ТОВ ТОМ-ІНВЕСТБУД",
    state: "JOURNAL_DUPLICATE",
    managerId: 58,
    storeId: 8,
    cost: 34577777.0,
    discount: 0.0,
    prepaymentPercent: 25.0,
    periodType: {
      value: "M60"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "dfgh",
        model: "43",
        cost: 34577777.0,
        discount: 0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "1032368866",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "33282104D9M58S8T1626341875991",
    crDt: "2021-07-15T12:38:25.028454",
    jurOkpo: "33282104",
    jurName: "ТОВ ТОМ-ІНВЕСТБУД",
    state: "JOURNAL_DUPLICATE",
    managerId: 58,
    storeId: 8,
    cost: 45677777.0,
    discount: 0.0,
    prepaymentPercent: 30.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 3,
          nameDesc: "Спецтехніка",
          subObjs: [],
          value: "special_equipment"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "fgdh",
        model: "34",
        cost: 45677777.0,
        discount: 0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "1032368866",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "33282104D9M58S8T1626168041832",
    crDt: "2021-07-13T12:21:14.576638",
    jurOkpo: "33282104",
    jurName: "ТОВ ТОМ-ІНВЕСТБУД",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 650000.0,
    discount: 0.0,
    prepaymentPercent: 20.0,
    periodType: {
      value: "M60"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 5,
          nameDesc: "Самохідна сільгосптехніка",
          subObjs: [],
          value: "self_propelled_agricultural_machinery"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "fgr",
        model: "5",
        cost: 650000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "1032368866",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "39104926D9M58S8T1626167521179",
    crDt: "2021-07-13T12:12:29.03822",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_DUPLICATE",
    managerId: 58,
    storeId: 8,
    cost: 349000.0,
    discount: 0.0,
    prepaymentPercent: 25.0,
    periodType: {
      value: "M60"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "уке",
        model: "ек5",
        cost: 349000.0,
        discount: 0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "33282104D9M58S8T1626167467736",
    crDt: "2021-07-13T12:11:42.283293",
    jurOkpo: "33282104",
    jurName: "ТОВ ТОМ-ІНВЕСТБУД",
    state: "JOURNAL_DUPLICATE",
    managerId: 58,
    storeId: 8,
    cost: 34534500.0,
    discount: 0.04,
    prepaymentPercent: 30.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 11.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "гршло",
        model: "енг",
        cost: 34534500.0,
        discount: 0.04
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "1032368866",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "39104926D9M58S8T1626166969540",
    crDt: "2021-07-13T12:04:46.705129",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_DUPLICATE",
    managerId: 58,
    storeId: 8,
    cost: 3500000.0,
    discount: 2.0,
    prepaymentPercent: 25.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 5.0,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "ford",
        model: "mustang",
        cost: 3500000.0,
        discount: 2.0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "12010330",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "33282104D9M58S8T1626166698580",
    crDt: "2021-07-13T11:59:24.205205",
    jurOkpo: "33282104",
    jurName: "ТОВ ТОМ-ІНВЕСТБУД",
    state: "JOURNAL_ACCEPTED",
    managerId: 58,
    storeId: 8,
    cost: 2357000.0,
    discount: 2.0,
    prepaymentPercent: 35.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 8.5,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 4,
          nameDesc: "Навісна сільгосптехніка",
          subObjs: [],
          value: "attached_agricultural_machinery"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "татра",
        model: "мв-2",
        cost: 2357000.0,
        discount: 2.0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    journalRef: "8C9E63A8CA0932C97D3AF5049F0F818A",
    cpAuthor: "Пупкин Фродо",
    personEkbId: "1032368866",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "39104926D9M58S8T1626083113547",
    crDt: "2021-07-12T12:47:02.000772",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_DUPLICATE",
    managerId: 58,
    storeId: 8,
    cost: 1550000.0,
    discount: 2.0,
    prepaymentPercent: 30.0,
    periodType: {
      value: "M12"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.5,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 8,
          nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
          subObjs: [],
          value: "commercial_auto_less_1"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "test",
        model: "asd23",
        cost: 1550000.0,
        discount: 2.0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "12010330",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "39104926D9M59S7T1625607712162",
    crDt: "2021-07-07T00:43:14.148328",
    jurOkpo: "39104926",
    jurName: "ТОВ ЦЕНТР-ШИНА ГРУП",
    state: "JOURNAL_DUPLICATE",
    managerId: 59,
    storeId: 7,
    cost: 1500000.0,
    discount: 5.0,
    prepaymentPercent: 30.0,
    periodType: {
      value: "M48"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 7.5,
    canBeSendToBank: false,
    pledgeQuantity: 1,
    pledgeDetails: [
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 7,
          nameDesc: "Спецтехніка JCB",
          subObjs: [],
          value: "st_jcb"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "vv2er",
        model: "23",
        equipmentDetail: "test",
        cost: 1500000.0,
        discount: 5.0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Бильбо",
    personEkbId: "25192594",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  },
  {
    cpId: "33282104D9M58S8T1624301826485",
    crDt: "2021-06-21T21:57:15.956887",
    jurOkpo: "33282104",
    jurName: "ТОВ ТОМ-ІНВЕСТБУД",
    state: "CP_SAVED",
    managerId: 58,
    storeId: 8,
    cost: 5000000.0,
    discount: 1.6,
    prepaymentPercent: 20.0,
    periodType: {
      value: "M24"
    },
    scheduleType: {
      value: "CLASSIC"
    },
    scheduleDetail: [],
    stake: 6.0,
    canBeSendToBank: false,
    pledgeQuantity: 2,
    pledgeDetails: [
      {
        pledgeDetailId: 2,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "brand3",
        model: "model3",
        equipmentDetail: "equipmentDetail3",
        cost: 3000000.0,
        discount: 2.0
      },
      {
        pledgeDetailId: 1,
        objectDetail: {
          id: 1,
          nameDesc: "Легкові авто",
          subObjs: [],
          value: "private_auto"
        },
        conditionType: {
          value: "NEW"
        },
        brand: "toyota",
        model: "corolla",
        equipmentDetail: "equipmentDetail3",
        cost: 2000000.0,
        discount: 1.0
      }
    ],
    orgName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
    orgOkpo: "85624199",
    storeName: "",
    hasUaJournalState: false,
    cpAuthor: "Пупкин Фродо",
    personEkbId: "1032368866",
    needCompleteMeet: false,
    program: {
      value: "FL"
    }
  }
];

// api/currentCondition
const currentCondition = {
  content: {
    cpId: "39104926D9M58S8T1697468592850",
    task: {
      cpId: "39104926D9M58S8T1697468592850",
      okpo: "39104926",
      createDt: "2023-10-16T18:03:12.869672",
      finalDt: "2023-10-16T18:03:28.668896",
      cpType: "DEALER"
    },
    clientData: {
      okpo: "39104926",
      orgName: "ТОВ ЦЕНТР-ШИНА ГРУП",
      phoneDataList: [
        {
          id: 1,
          maskedPhone: "+3806304***33"
        },
        {
          id: 2,
          maskedPhone: "+3809600***29"
        },
        {
          id: 3,
          maskedPhone: "+3805024***55"
        },
        {
          id: 4,
          maskedPhone: "+3809600***29"
        },
        {
          id: 5,
          maskedPhone: "+3806763***86"
        },
        {
          id: 6,
          maskedPhone: "+3806755***49"
        },
        {
          id: 7,
          maskedPhone: "+3806375***32"
        },
        {
          id: 8,
          maskedPhone: "+3805144***22"
        },
        {
          id: 9,
          maskedPhone: "+3805144***22"
        }
      ],
      emailDataList: [
        {
          id: 1,
          maskedEmail: "MPS****OV@GMAIL.COM"
        },
        {
          id: 2,
          maskedEmail: "VJA*******.***AS@PRIVATBANK.UA"
        },
        {
          id: 4,
          maskedEmail: "VJA*******.***AS@PRIVATBANK.UA"
        },
        {
          id: 5,
          maskedEmail: "630***6N@GMAIL.COM"
        },
        {
          id: 6,
          maskedEmail: "KAZ******1V@GMAIL.COM"
        },
        {
          id: 7,
          maskedEmail: "STA********.*******AV@GMAIL.COM"
        }
      ],
      flAgro: "N"
    },
    sellerData: {
      orgId: 9,
      sellerOkpo: "85624199",
      sellerName: "ТОВ TEST ФІНЛІЗИНГ МСБ КАБІНЕТ ДИЛЕРА",
      sellerStoreName: "ВАЗ1",
      flagHo: false
    },
    state: "INIT_CLIENT_DATA_FINISHED",
    fundProgram: {
      value: "FL_579"
    }
  },
  finalRespState: true
};

// api/defaults
const defaults = {
  schedules: [
    {
      value: "CLASSIC"
    },
    {
      value: "CUSTOM"
    }
  ],
  periodTypes: [
    {
      value: "M12"
    },
    {
      value: "M24"
    },
    {
      value: "M36"
    },
    {
      value: "M48"
    },
    {
      value: "M60"
    }
  ],
  prepaymentTypes: [
    {
      value: "P20"
    },
    {
      value: "P25"
    },
    {
      value: "P30"
    },
    {
      value: "P35"
    },
    {
      value: "P40"
    },
    {
      value: "P45"
    },
    {
      value: "P50"
    },
    {
      value: "P55"
    },
    {
      value: "P60"
    },
    {
      value: "P65"
    },
    {
      value: "P70"
    }
  ],
  conditionTypes: [
    {
      value: "NEW"
    },
    {
      value: "SECOND_HAND"
    }
  ],
  objectDetails: [
    {
      id: 7,
      nameDesc: "Спецтехніка JCB",
      newPrepayment: 20.0,
      secondHandPrepayment: 30.0,
      matrixId: "4",
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "OPT_NO",
      flRegSh: "OPT_NO",
      sumRegNew: 4000.0,
      sumRegSh: 4000.0,
      subObjs: [],
      value: "st_jcb"
    },
    {
      id: 2,
      nameDesc: "Комерційний транспорт",
      newPrepayment: 20.0,
      secondHandPrepayment: 30.0,
      matrixId: "3",
      flPfNew: "OPT_NO",
      flPfSh: "NO",
      flRegNew: "YES",
      flRegSh: "YES",
      sumRegNew: 1500.0,
      sumRegSh: 1500.0,
      subObjs: [
        {
          id: 3,
          nameDesc: "Причепи та напівпричепи"
        },
        {
          id: 4,
          nameDesc: "Автобуси, Мікроавтобуси"
        },
        {
          id: 1,
          nameDesc: "Вантажні ТЗ вантажепід`ємністю від 2500 кг"
        },
        {
          id: 2,
          nameDesc:
            "Фургони та вантажні ТЗ вантажепід`ємністю від 1000 кг до 2500 кг"
        }
      ],
      value: "commercial_auto"
    },
    {
      id: 3,
      nameDesc: "Спецтехніка",
      newPrepayment: 20.0,
      secondHandPrepayment: 30.0,
      matrixId: "3",
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "OPT_NO",
      flRegSh: "OPT_NO",
      sumRegNew: 4000.0,
      sumRegSh: 4000.0,
      subObjs: [],
      value: "special_equipment"
    },
    {
      id: 6,
      nameDesc: "Обладнання",
      newPrepayment: 30.0,
      matrixId: "3",
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "NO",
      flRegSh: "NO",
      subObjs: [],
      value: "equipment"
    },
    {
      id: 8,
      nameDesc: "Комерційні авто вантажопідйомністю до 1 тони",
      newPrepayment: 20.0,
      secondHandPrepayment: 30.0,
      matrixId: "3",
      flPfNew: "OPT_NO",
      flPfSh: "NO",
      flRegNew: "YES",
      flRegSh: "YES",
      sumRegNew: 1500.0,
      sumRegSh: 1500.0,
      subObjs: [],
      value: "commercial_auto_less_1"
    },
    {
      id: 4,
      nameDesc: "Навісна сільгосптехніка",
      newPrepayment: 30.0,
      matrixId: "3",
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "NO",
      flRegSh: "NO",
      subObjs: [],
      value: "attached_agricultural_machinery"
    },
    {
      id: 5,
      nameDesc: "Самохідна сільгосптехніка",
      newPrepayment: 20.0,
      secondHandPrepayment: 30.0,
      matrixId: "3",
      flPfNew: "NO",
      flPfSh: "NO",
      flRegNew: "YES",
      flRegSh: "YES",
      sumRegNew: 4000.0,
      sumRegSh: 4000.0,
      subObjs: [],
      value: "self_propelled_agricultural_machinery"
    }
  ],
  adaptDetails: [
    {
      value: "JAN"
    },
    {
      value: "FEB"
    },
    {
      value: "MAR"
    },
    {
      value: "APR"
    },
    {
      value: "MAY"
    },
    {
      value: "JUN"
    },
    {
      value: "JUL"
    },
    {
      value: "AUG"
    },
    {
      value: "SEP"
    },
    {
      value: "OCT"
    },
    {
      value: "NOV"
    },
    {
      value: "DEC"
    }
  ],
  defaultStake: 11.0,
  uirdTypes: [
    {
      code: 1,
      description: "виручка до 2 млн. Євро"
    },
    {
      code: 2,
      description: "виручка  від 2 до 10 млн. Євро"
    },
    {
      code: 3,
      description: "виручка від 10 до 50 млн. Євро"
    },
    {
      code: 4,
      description: "виручка від 50 млн. Євро"
    }
  ],
  guarantee: false,
  editGuarantee: false
};

// api/calcPledgeDetails
const calcPledgeDetails = {
  totalCost: 15000000.0,
  totalDiscount: 0.0,
  totalPrepayment: 25.0,
  totalFunding: 11250000.0,
  currencyTotalPrepayment: 3750000.0,
  currencyTotalPrepaymentPFReg: 3754000.0,
  discountBankCurrency: 0.0,
  totalWithoutPrep: 11250000.0,
  totalPF: 0.0,
  totalReg: 4000.0,
  totalCostPFReg: 15004000.0,
  monthCommission: {
    commPercent: 0.05,
    commSum: 7502.0
  }
};

// api/getPledgeDetailsStake
const getPledgeDetailsStake = {
  interestRate: 11.0,
  onceCommissionSum: 168750.0,
  prcComm: 1.5,
  uird: 0,
  rateUIRD: ""
};

// api/progr579
const progr579 = { rate: 11, value: "FL_11" };

// esa/cp/progr579?cpId=E37518714T1698761278&uirdId=1
const changeUrid = {
  rate: 11.0,
  value: "FL_11"
};

// esa/cp/calcPledgeDetails
const calcPledgeDetailsEsa = {
  totalCost: 1500000.0,
  totalDiscount: 0.0,
  totalPrepayment: 25.0,
  totalFunding: 1125000.0,
  currencyTotalPrepayment: 375000.0,
  currencyTotalPrepaymentPFReg: 376500.0,
  discountBankCurrency: 0.0,
  totalWithoutPrep: 1125000.0,
  totalPF: 0.0,
  totalReg: 1500.0,
  totalCostPFReg: 1501500.0,
  monthCommission: {
    commPercent: 0.29,
    commSum: 4354.35
  }
};

// api/getPledgeDetailsStake
const getPledgeDetailsStakeEsa = {
  interestRate: 11.0,
  onceCommissionSum: 16875.0,
  prcComm: 1.5,
  uird: 0,
  rateUIRD: ""
};

const meetData = {
  isMeetRequiredCp: true,
  links: [
    {
      name: "Головний бухгалтер - Лемпій Алег Юрьевич",
      ekbId: 1007247811
    },
    {
      name: "Директор - Комос Зиновий Юрійович",
      ekbId: 15006992
    },
    {
      name: "Директор - Кіндер Замир Вячеславович",
      ekbId: 1000417750
    },
    {
      name: "Директор - Шавров Максим",
      ekbId: 22383228
    }
  ]
};
const statistic = {
  statisticsLdapDtoList: [
    {
      authorLdap: "DG030588TVL",
      statisticsTimelapsesDto: {
        mapStatisticsToTimelapse: {
          "2022-09-01": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-02": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-03": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-04": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-05": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-06": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-07": {
            givenOnes: 500000,
            creditGivenOnes: 499975,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-08": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-09": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-10": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-11": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-12": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          }
        }
      }
    },
    {
      authorLdap: "DN180990SVO1",
      statisticsTimelapsesDto: {
        mapStatisticsToTimelapse: {
          "2022-09-01": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-02": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-03": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-04": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-05": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-06": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-07": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-08": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-09": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-10": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-11": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-12": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          }
        }
      }
    },
    {
      authorLdap: "DN140583SMP",
      statisticsTimelapsesDto: {
        mapStatisticsToTimelapse: {
          "2022-09-01": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-02": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-03": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-04": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-05": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-06": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-07": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-08": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-09": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-10": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-11": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-12": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          }
        }
      }
    },
    {
      authorLdap: "DN151169RNI1",
      statisticsTimelapsesDto: {
        mapStatisticsToTimelapse: {
          "2022-09-01": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-02": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-03": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-04": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-05": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-06": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-07": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-08": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-09": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-10": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-11": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-12": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          }
        }
      }
    },
    {
      authorLdap: "DN090884FTM",
      statisticsTimelapsesDto: {
        mapStatisticsToTimelapse: {
          "2022-09-01": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-02": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-03": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-04": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-05": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-06": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-07": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-08": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-09": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-10": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-11": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-12": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          }
        }
      }
    },
    {
      authorLdap: "DN080982PSV",
      statisticsTimelapsesDto: {
        mapStatisticsToTimelapse: {
          "2022-09-01": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-02": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-03": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-04": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-05": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-06": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-07": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-08": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-09": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-10": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-11": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-12": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          }
        }
      }
    },
    {
      authorLdap: "DN010792PNA1",
      statisticsTimelapsesDto: {
        mapStatisticsToTimelapse: {
          "2022-09-01": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-02": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-03": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-04": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-05": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-06": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-07": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-08": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-09": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-10": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-11": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-12": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          }
        }
      }
    },
    {
      authorLdap: "DN050981ZVM",
      statisticsTimelapsesDto: {
        mapStatisticsToTimelapse: {
          "2022-09-01": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-02": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-03": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-04": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-05": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-06": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-07": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-08": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-09": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-10": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-11": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-12": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          }
        }
      }
    },
    {
      authorLdap: "DN090991AST",
      statisticsTimelapsesDto: {
        mapStatisticsToTimelapse: {
          "2022-09-01": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-02": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-03": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-04": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-05": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-06": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-07": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-08": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-09": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-10": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-11": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          },
          "2022-09-12": {
            givenOnes: 0,
            creditGivenOnes: 0,
            approvingOnes: 0,
            creditApprovingOnes: 0
          }
        }
      }
    }
  ],
  statisticsTimelapsesDto: {
    mapStatisticsToTimelapse: {
      "2022-09-01": {
        givenOnes: 0,
        creditGivenOnes: 0,
        approvingOnes: 0,
        creditApprovingOnes: 0
      },
      "2022-09-02": {
        givenOnes: 0,
        creditGivenOnes: 0,
        approvingOnes: 0,
        creditApprovingOnes: 0
      },
      "2022-09-03": {
        givenOnes: 0,
        creditGivenOnes: 0,
        approvingOnes: 0,
        creditApprovingOnes: 0
      },
      "2022-09-04": {
        givenOnes: 0,
        creditGivenOnes: 0,
        approvingOnes: 0,
        creditApprovingOnes: 0
      },
      "2022-09-05": {
        givenOnes: 0,
        creditGivenOnes: 0,
        approvingOnes: 0,
        creditApprovingOnes: 0
      },
      "2022-09-06": {
        givenOnes: 0,
        creditGivenOnes: 0,
        approvingOnes: 0,
        creditApprovingOnes: 0
      },
      "2022-09-07": {
        givenOnes: 500000,
        creditGivenOnes: 499975,
        approvingOnes: 0,
        creditApprovingOnes: 0
      },
      "2022-09-08": {
        givenOnes: 0,
        creditGivenOnes: 0,
        approvingOnes: 0,
        creditApprovingOnes: 0
      },
      "2022-09-09": {
        givenOnes: 0,
        creditGivenOnes: 0,
        approvingOnes: 0,
        creditApprovingOnes: 0
      },
      "2022-09-10": {
        givenOnes: 0,
        creditGivenOnes: 0,
        approvingOnes: 0,
        creditApprovingOnes: 0
      },
      "2022-09-11": {
        givenOnes: 0,
        creditGivenOnes: 0,
        approvingOnes: 0,
        creditApprovingOnes: 0
      },
      "2022-09-12": {
        givenOnes: 0,
        creditGivenOnes: 0,
        approvingOnes: 0,
        creditApprovingOnes: 0
      }
    }
  }
};

const mapData = {
  userInfoEsa: userInfoEsa,
  defaultsEsa: defaultsEsa,
  findCpEsa: findCpEsa,
  getAllOrg: getAllOrg,
  org: org,
  newOrg: newOrg,
  manager: manager,
  otp: otp,
  userInfo: userInfo,
  allPrograms: allPrograms,
  findCp: findCp,
  currentCondition: currentCondition,
  defaults: defaults,
  calcPledgeDetails: calcPledgeDetails,
  progr579: progr579,
  getPledgeDetailsStake: getPledgeDetailsStake,
  defaultsEsaCp: defaultsEsaCp,
  addCalcTaskEsa: addCalcTaskEsa,
  currentConditionESA: currentConditionESA,
  changeUrid: changeUrid,
  calcPledgeDetailsEsa: calcPledgeDetailsEsa,
  getPledgeDetailsStakeEsa: getPledgeDetailsStakeEsa,
  meetData: meetData,
  statistic: statistic
};

const createMockData = id => {
  console.log("createMockData", id);
  return mapData[id];
};

export default createMockData;
